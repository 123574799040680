.c-btn {
  font-family: BebasNeue;
  font-size: 2.5rem;
  line-height: 1;
  text-align: center;
  text-transform: capitalize;
  border-radius: 50px;
  border: none;
  outline:none;
  font-weight: bold;
  transition-duration: 0.4s;
  display: inline-block;
  padding: 0.625rem 1.75rem;

  &:hover:not(:disabled){
   // transform: scale(1.1) !important;
  }
  &:disabled {
    transform: none;
    opacity: 0.23;
  }

  &.large {
    font-size: 1.875rem;
    padding:0.625rem 1.75rem;
  }
  &.medium {
    font-size: 1.5rem;
    padding:0.5rem 1.5rem;
  }
  &.small {
    font-size: 1.125rem;
    padding:0.375rem 1.25rem;
  }
  &.x-small {
    font-size: 0.8125rem;
    padding: 0.25rem 1rem;
  }
}

a.c-btn:hover:not(:disabled),
a.c-btn:focus:not(:disabled)  {
  color:#fff;
}


.c-btn-blue {
  background-color: $c-blue;
  color: white;
  &.no-action {
    pointer-events: none;
  }
  &:hover {
    background-color: $c-dark-blue;
  }
}

.c-btn-br-blue {
  background-color: $c-bright-blue;
  color: white;
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $c-blue;
  }
}

.c-btn-purple {
  background-color: $c-purple;
  color: white;
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $c-dark-purple;
  }
}

.c-btn-dark-blue {
  background-color: $c-dark-blue;
  color: white;
  &:hover:not(:disabled),
  &:focus:not(:disabled)  {
    background-color: $c-navy;
  }
}

.c-btn-green {
  background-color: $c-green;
  color: white;
  &.no-action {
    pointer-events: none;
  }
  &:hover:not(:disabled),
  &:focus:not(:disabled)  {
    background-color: $c-dark-green;
  }
}

.c-btn-yellow {
  background-color: $c-yellow;
  color: white !important; 
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $c-dark-yellow; 
  }
}

.c-btn-teal {
  background-color: $c-teal;
  color: white;
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $c-dark-teal;
  }
}

.c-btn-orange {
  background-color: $c-orange;
  color: white;
  &:hover:not(:disabled),
  &:focus:not(:disabled)  {
    background-color: $c-dark-orange;
  }
}

.c-btn-white {
  background-color: $c-white;
  color: $c-blue;
  &:hover:not(:disabled), 
  &:focus:not(:disabled) {
    background-color: $header-hover;
  }
}

.c-btn-gray {
  background-color: $c-dark-gray;
  color: white;
  &:disabled {
    background-color: $c-med-gray;
    opacity: 1;
  }
}

.m-size{
  min-width: 185px;
  height: 40px;
}