@import "../core/variables";

.accordion {
  .inf-section-content {
    @media (max-width: 576px) {
      padding: 1.5rem;
    }
  }
}



.faqs-accordion {


  .card {
    border: none;
    border-bottom: 1px solid #D8D8D8;
    border-radius: 0px;

    .card-header {
      border: none;
      position: relative;
      cursor: pointer;

      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.8125rem;
        color: $c-dark-blue;
      }

      &.collapsed {
        &:before {
          content: '';
          height: 31px;
          width: 31px;
          border-radius: 50%;
          border: 2px solid $c-blue !important;
          background-color: white;
          position: absolute;
          left: -25px;
          top: 0px;
          bottom: 0px;
          margin: auto 0px;
        }

        &:after {
          content: '+';
          height: 31px;
          font-size: 30px;
          line-height: 1.625rem;
          width: 31px;
          border-radius: 50%;
          border: 2px solid #0072ce !important;
          color: #0072ce;
          text-align: center;
          position: absolute;
          left: -25px;
          top: 0px;
          bottom: 0px;
          margin: auto 0px;
        }
      }

      &:before {
        content: '';
        background-color: $c-blue;
        height: 31px;
        width: 31px;
        border-radius: 50%;
        border: 2px solid $c-blue;
        position: absolute;
        left: -25px;
        top: 0px;
        bottom: 0px;
        margin: auto 0px;
      }

      &:after {
        content: '';
        background-color: $c-white;
        height: 2px;
        width: 16px;
        position: absolute;
        left: -17px;
        top: 0px;
        bottom: 0px;
        margin: auto 0px;
      }
    }

    .card-body {

    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.general-qestion, .how-it-works-qestion, .about-books-qestion, .for-parents-qestion, .national-design-qestion {

  .section-content {
    padding: 4.375rem 1rem;
  }
}
