// RESET/BASE CSS:
@import "core/_variables";
@import "core/_helper-classes";
@import "core/_normalize";
@import "core/_base";
@import "core/_buttons";
@import "core/typography";
@import "core/_icons";
@import "modules/_owl-nav";
@import "modules/_slick-nav";
@import "modules/_swicher";
@import "modules/_header";
@import "modules/_footer";
@import "modules/_cards";
@import "modules/_poll-widget";
@import "modules/_blogpost-widget";
@import "modules/_trivia-widget";
@import "modules/_page-common";
@import "modules/section-header";
@import "modules/section-content";
@import "modules/section-promo";
@import "modules/_wysiwyg";
@import "modules/_comments";
@import "modules/_form-controls";
@import "modules/_book-details";
@import "modules/_grid-list-card";
@import "modules/_pagedlist";
@import "modules/_text-widget";
@import "modules/_loader";
// Mixin
@import "helpers/mixins";
@import "pages/home";
@import "pages/modal";
@import "pages/form-element";
@import "pages/blog";
@import "pages/contact";
@import "pages/about";
@import "pages/for-authors";
@import "pages/faqs";
@import "pages/story-central";
@import "pages/membership";
@import "pages/parents-guide";
@import "pages/member-bookshelf";
@import "pages/member-badges";
@import "pages/member-account";
@import "pages/member-activity";
@import "pages/search-results";
// PAGES BASED ON BODY CLASS:
@import "modules/_modules";
// only for dev______________________
.dev-test {
    &.hide-dev-menu {
        right: -220px;
        .btn-hide-dev-menu {
            transition: 1s;
            transform: rotate(180deg);
        }
    }
    .btn-hide-dev-menu {
        position: absolute;
        border-radius: 20px;
        left: 2px !important;
        transition: 0.5s;
        cursor: pointer;
        top: 37%;
        border: 1px solid #6f6e6e;
        padding: 0px 5px;
        font-size: 22px !important;
        background-color: #2218dc17 !important;
        &:hover {
            color: #b6b6b6;
            background-color: #07009473 !important;
        }
    }
    z-index: 1000;
    border: 1px solid #9c9c9c;
    padding: 20px;
    padding-left: 30px;
    position: fixed;
    transition: 0.7s;
    top: 200px;
    right: 10px;
    border-radius: 15px;
    background-color: rgba(1, 72, 130, 0.78);
    box-shadow: 1px 0px 31px 0px #d6d6d6;
    max-width: 250px;
    h6 {
        color: white;
    }
    div {
        text-align: center;
        font-size: 20px;
        color: #d0caca;
        text-transform: uppercase;
        border-bottom: 1px solid #6f6f6f;
        margin-top: -15px;
    }
    a,
    i {
        color: gold;
        cursor: pointer;
        width: 100%;
        display: inline-block;
        border-bottom: 1px solid transparent;
        &:before {
            content: ">";
            color: #a7a7a7;
            padding-right: 7px;
        }
        &:hover {
            color: goldenrod;
            border-bottom: 1px solid #6f6f6f;
        }
    }
    span {
        color: #fbfbfb;
        font-size: 12px;
        word-wrap: break-word;
        line-height: 1;
        padding-top: 5px;
        float: left;
        margin-top: 12px;
    }
}

@media (max-width: 490px) {
    .dev-test {
        right: -220px;
        .btn-hide-dev-menu {
            transition: 1s;
            transform: rotate(180deg);
        }
        &.hide-dev-menu {
            right: -2px;
            .btn-hide-dev-menu {
                transition: 1s;
                transform: rotate(360deg);
            }
        }
    }
}

// ___________________________________