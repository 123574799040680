.owl-carousel {
  .owl-nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 165px;
    width: 100%;

    & .owl-next,
    & .owl-prev {
      color: white;
      border-radius: 50%;
      font-size: 24px;
      height: 48px;
      width: 48px;
      background-color: $c-dark-blue;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:hover {
        background-color: $c-blue;
      }
    }
  }

  .owl-dots {
    position: absolute;
    bottom: 60px;
    width: 100%;
    .owl-dot {
      span {
        background-color: rgba(0, 45, 114, 0.3);
        width: 0.875rem;
        height: 0.875rem;
      }
      &.active span {
        background-color: $c-dark-blue;
      }
    }
  }
}

.widget-carousel {
  &.owl-carousel {
    .owl-nav {
      top: 100px;
      & .owl-prev {
        left: -8%;
      }
      & .owl-next {
        right: -8%;
      }
    }
    .owl-dots {
      bottom: -3.5rem;
    }
  }
}
