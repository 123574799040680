.blog-post {
  background: $c-white;
  border-top: 10px solid $c-yellow;
  font-family: Nunito;

  &-shadow {
    box-shadow: 0px 2px 12px rgba(0, 45, 114, 0.4);
  }

  .video-blog-thumb {
    color: #fff;
    position: absolute;
    font-size: 3.625rem;
    bottom: 0;
    line-height: 1;
    left: 0.75rem;
    @media screen and (min-width: $break-md) {
      font-size: 5.625rem;
    }
  }

  &-image {
    position: relative;

    img {
      max-width: 100%;
      width: 100%;
    }

    &-lg {
      @media screen and (min-width: $break-md) {
        flex-direction: column;
      }
    }

    &-bg {
      background-repeat: no-repeat;
      background-size: cover;
      background-position:center center;
      flex: 12;
      width: 100%;
      height: 100%;
      position: relative;
      @media screen and (min-width: $break-md) {
        min-height: 362px;
      }
    }
  }

  &-date {
    color: $c-white;
    text-align: center;
    line-height: 1.375rem;
    background-color: $c-bright-blue;
    padding: 0.25rem 1.125rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-day {
      font-family: BebasNeue;
      font-size: 2.75rem;
      line-height: 1;
      @media screen and (min-width: $break-md) {
        font-size: 4rem;
      }
    }
  }

  &-title {
    font-size: 1.3125rem;
    line-height: 1.75rem;
    color: $c-blue;
    padding: 0.25rem 1.25rem;
    align-items: center;
    display: flex;
    flex: 1;
    border-bottom: 1px solid rgba(151, 151, 151, 0.3);
    & > a {
      color: $c-blue;
    }
    @media screen and (min-width: $break-sm) {
      font-size: 1.75rem;
      line-height: 2.375rem;
    }
  }

  &-content {
    font-size: 1rem;
    line-height: 1.625rem;
    padding: 1.25rem 2rem 2.5rem;
    color: $c-dark-blue;
    @media screen and (min-width: $break-md) {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }

  &-bottom {
    background: $c-dark-blue;
    color: $c-white;
    font-size: 0.8125rem;
    line-height: 1.125rem;
    padding: 0.75rem;
    flex-wrap: wrap;
    align-items: center;
    a {
      color: $c-white;
    }

    i.icon {
      font-size: 1.125rem;
      margin-right: 0.5rem;
      color: $c-yellow;

      .parent & {
        color: $c-bright-blue;
      }
    }
  }

  &-by {
    padding: 0 0.75rem;
    margin: 0.25rem 0;
    flex-wrap: wrap;
    a {
      &:after {
        content: ",";
      }
      &:last-of-type:after {
        content: "";
      }
    }
  }

  &-tags {
    padding: 0 0.75rem;
    margin: 0.25rem 0;
    flex-wrap: wrap;
    a {
      text-transform: capitalize;
      &:after {
        content: ",";
      }
      &:last-of-type:after {
        content: "";
      }
    }
  }

  &-full-width {
    display: flex;
    flex-wrap: wrap;

    .blog-post-bottom {
      flex: 0 0 100%;
    }

    @media screen and (min-width: $break-md) {
      .blog-post-image-lg .blog-post-bottom {
        flex: 1;
        display: flex !important;
      }
    }

    .blog-post-image .blog-post-image-bg {
      @media screen and (min-width: $break-sm) {
        min-height: 163px;
      }

      @media screen and (min-width: $break-md) {
        min-height: 192px;
      }
    }

    @media screen and (min-width: $break-sm) {
      .blog-post-wrapper {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

.widget-carousel .blog-widget {
  &.blog-post {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;
  }

  .blog-post-content {
    flex: 2;
  }
}

.parent .blog-post {
  border-top: 10px solid $c-blue;
  .c-btn {
    background-color: $c-blue;
  }
}
