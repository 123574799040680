.book-details {
  overflow: hidden;
  .comment-avatar {
    flex: 1;
  }
}

.book-teaser {
  position: relative;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  @media screen and (min-width: $break-md) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  @media screen and (min-width: $break-md) {
    margin-bottom: -11rem;
    margin-left: 6%;
    flex: 0 0 22%;
    max-width: 22%;
  }

  img {
    width: 100%;
  }
}

.book-summary {
  color: $c-navy;
  font-size: 1.25rem;
  line-height: 1.875rem;
  padding: 2rem 0 2rem 1rem;

  @media screen and (min-width: $break-md) {
    padding: 3rem 0 3rem 1.5rem;
    margin-left: calc(28% + 15px);
  }

  .login-hint {
    display: flex;
    font-family: GamjaFlower;
    font-size: 1.55rem;
    color: $c-dark-blue;
    margin-bottom: 0.5rem;
    line-height: 1.8125rem;
  }
}
.book-options {
  color: $c-dark-blue;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.625rem;
  span {
    font-size: 0.75rem;
    line-height: 1rem;
    color: #57b1f9;
  }
  i {
    font-size: 1rem;
    line-height: 1;
    color: $c-bright-blue;
    display: inline-block;
    vertical-align: middle;
  }
}
.book-raiting-summary {
  h4 {
    color: $c-dark-blue;
  }
}
.rating-stars {
  color: $c-orange;
  min-width: 101px;
  margin-left: 1rem;
  &.disabled {
    opacity: 0.5;
  }
}
.reviews-number {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: $c-orange;
  span {
    color: $c-white;
    font-family: BebasNeue;
    font-size: 1rem;
    line-height: 1;
    display: inline-block;
    background-color: $c-orange;
    //width: 1.5rem;
    height: 1.75rem;
    text-align: center;
    border-radius: 50%;
    padding: 0.375rem;
  }
}
.book-preview-link {
  color: #fff;
  font-size: 1.25rem;
  position: absolute;
  border-bottom: 4.375rem solid $c-yellow;
  border-left: 4.375rem solid transparent;
  bottom: 0;
  right: 0;

  .icon-link,
  span {
    display: none;
  }

  .icon-loop {
    position: absolute;
    bottom: -4.25rem;
    right: 1rem;
  }

  @media screen and (min-width: $break-sm) {
    border-bottom: 5.125rem solid $c-yellow;
    border-left: 5.125rem solid transparent;

    .icon-loop {
      bottom: -4.625rem;
    }
  }
  @media screen and (min-width: $break-md) {
    background-color: $c-yellow;
    color: $c-dark-blue;
    font-size: 1rem;
    line-height: 1.375rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
    border-left: none;
    position: relative;
    .icon-link {
      font-size: 1.5rem;
    }
    span,
    .icon-link {
      display: inline-block;
    }

    .icon-loop {
      display: none;
    }
  }
}

.book-action-btns {
  margin-bottom: -1.25rem;
  z-index: 2;
  justify-content: center;
  display: flex;
  & + .page-header-big-bar {
    padding-top: 1.25rem;
  }
  .c-btn {
    margin: 0 0.5rem;
  }
  @media screen and (min-width: $break-sm) {
    justify-content: flex-end;
    & + .page-header-big-bar {
      padding-top: 0;
    }
  }
}
.related-books-slider,
.books-carousel {
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    margin: 0 15px;
    height: auto;
  }
}

#leave-review-box {
  display: flex;
  flex-direction: column;
}
