@font-face {
  font-family: BebasNeue;
  src: url("../fonts/BebasNeueBold.ttf") format("truetype"), url("../fonts/BebasNeueBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: BebasNeue;
  src: url("../fonts/BebasNeueRegular.ttf") format("truetype"), url("../fonts/BebasNeueRegular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Bangers;
  src: url("../fonts/Bangers.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: GamjaFlower;
  src: url("../fonts/GamjaFlower-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

//LucidaGrande
@font-face {
  font-family: LucidaGrande;
  src: url("../fonts/LucidaGrande.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

//Nunito

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Nunito-Light.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/Nunito-Black.ttf") format("truetype");
}

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  /*font-family: "Open Sans", sans-serif;*/
  -webkit-background-clip: padding-box;
  /*color: #000000;*/
  font-size: 16px;
  color: #555555;
  font-family: Nunito;
}

html {
  scroll-behavior: smooth;
}

button {
  font-family: BebasNeue;
  outline: none;
  border: none;
  user-select: none;
}

button:active {
  outline: none !important;
  border: none;
}

button:focus {
  outline: none !important;
  border: none;
}

ul {
  margin: 0;
  padding: 0;
  li {
    display: block;
    list-style: none;
  }
}

a,
a:visited,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

input:focus {
  outline: none;
}

label {
  display: block;
  text-align: left;
}

strong {
  font-weight: bold;
}

p {
  margin: 0;
}
img {
  max-width: 100%;
}

@media (min-width: $break-lg) {
  .container {
    max-width: 1300px;
  }

  .container-fluid {
    max-width: 1464px;
  }
}
.modal {
  z-index: 99999;
}
/*
body {
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}*/
