.footer-text {
  font-family: LucidaGrande;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $c-dark-blue;
}

.footer-text a{
  color: $c-dark-blue;
  &:hover{
    text-decoration: underline;
  }
}


.global-footer {
  background-color: $c-white;
  .cont {
    display: flex;
    justify-content: space-between;
    padding: 25px 0px 33px 0px;
    align-items: center;


    .footer-icon {
      height: 31px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 100%;
        width: 100%;
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;
      //align-items: center;
      flex-wrap: wrap;

      .footer-icon {
        margin-top: 11px;
      }
    }
  }
}