.activity-badges-info {
  @include profileInfoSection(#00c7b1, #e7f7f5, #00c7b1, #00c7b1, #009484);
}

main {
  .activity {
    background-color: #f5f5f5;
    padding: 2.0625rem 1.375rem 4.375rem 1.375rem;
    @media screen and (min-width: 768px) {
      padding: 4.1875rem 2.5625rem 1.5rem 2.5625rem;
    }
    @media screen and (min-width: 992px) {
      border-left: 6px solid #ffffff;
      padding: 4.1875rem 4.5625rem 1.5rem 4.5625rem;
    }
    .daily-activity {
      position: relative;
      &-item {
        &:after {
          content: "";
          position: absolute;
          bottom: 2.125rem;
          height: 1px;
          left: 0;
          right: 0;
          width: 85%;
          margin: auto;
          background: #d8d8d8;
          @media screen and (min-width: 768px) {
            width: 95%;
            left: unset;
            right: unset;
          }
        }
      }
      &:last-child {
        .daily-activity-item {
          &:after {
            display: none;
          }
        }
      }
      font-family: Nunito;
      &-title {
        h4 {
          font-weight: 600;
          font-size: 1.75rem;
          line-height: 2.375rem;
          color: #002d72;
          margin-bottom: 1.75rem;
        }
      }
      &-comment {
        &--video {
          .daily-activity-comment-text {
            border-top-left-radius: 0;
            //border-top-right-radius: 0;
            @media screen and (min-width: 992px) {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
            p {
              padding-bottom: 0;
            }
          }
        }
        .rating-stars {
          padding-bottom: 1.5rem;
          padding-left: 1.5rem;
          i {
            font-size: 1.4rem;
            color: #f4a800;
            &:not(:last-child) {
              margin-right: 0.4rem;
            }
          }
        }
        i {
          font-size: 2rem; // Here Edit
        }
        &.way-to-go {
          i,
          h2 {
            color: #702082;
          }
        }
        &.on-the-way {
          i,
          h2 {
            color: #f28028;
          }
        }
        &.completed {
          i,
          h2 {
            color: #78be20;
          }
        }
        &.snazzy {
          i,
          h2 {
            color: #0072ce;
          }
        }
        &:last-child {
          margin-bottom: 5rem;
        }
        &:nth-child(n + 3) {
          margin-top: 2.8125rem;
        }
        &-icon {
          i {
            color: #00c7b1;
            line-height: normal; //Edit Here
          }
          //   margin-right: 0.4375rem; //Edit Here
          min-width: 50px; //Edit Here
        }
        &-info {
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.1875rem;
          color: #002d72;
          margin-bottom: 0.625rem;
          @media screen and (max-width: 767px) {
            p {
              flex-wrap: wrap;
            }
          }
        }
        &-text {
          background-color: #fff;
          border-radius: 0.625rem;
          box-shadow: 0 14px 28px rgba(#002d72, 0.25), 0 10px 10px rgba(#002d72, 0.22);
          p {
            font-style: normal;
            font-weight: normal;
            font-size: 1.25rem;
            line-height: 1.875rem;
            color: #002d72;
            padding: 1.5rem 1rem 1.5rem 1.5rem;
          }
          &-status {
            font-family: Nunito;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.375rem;
            display: flex;
            align-items: center;
            i {
              display: flex;
              font-size: 1rem;
              padding-right: 0.3125rem;
            }
            & .approved {
              color: #78be20;
            }
            & .not-approved {
              color: #434343;
            }
            & .pending {
              color: #f4a800;
            }
          }
          &-video {
            // margin-right: 0.625rem;
            .video-review-thumbnail {
              &:before {
                font-size: 2.5rem;
              }
              height: 100%;
              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                @media screen and (min-width: 992px) {
                  max-width: 25rem;
                }
              }
            }
          }
        }
        // Start Card
        &-card {
          border-radius: 0.625rem;
          box-shadow: 0 14px 28px rgba(0, 45, 114, 0.25), 0 10px 10px rgba(0, 45, 114, 0.22);
          &.book-card {
            .daily-activity-comment-card-body {
              padding: 1rem 0;
              @media screen and (min-width: 768px) {
                padding: 0 0;
              }

              &-text {
                padding: 0.6875rem 0.6875rem;
              }
            }
          }
          &.badge-card {
            .daily-activity-comment-card-body {
              padding: 0.6875rem 0.6875rem;
            }
          }
          &--success {
            .daily-activity-comment-card-head {
              background-color: #78be20;
              padding: 0.8rem 1rem;
            }
            .daily-activity-comment-card-body {
              i {
                font-size: 3.5rem;
                color: #78be20;
              }
              p {
                font-size: 1.5rem;
                font-weight: 600;
                color: #78be20;
              }
            }
          }
          &-head {
            padding: 0.8rem 1rem;
            background-color: #702082;
            border-top-left-radius: 0.625rem;
            border-top-right-radius: 0.625rem;
            h6 {
              font-weight: 600;
              font-size: 1rem;
              color: #ffffff;
              margin-bottom: 0;
              line-height: 1.5;
            }
          }
          &-body {
            background-color: #fff;
            padding: 1.875rem 1rem;
            border-radius: 0.625rem;
            @media screen and (min-width: 768px) {
              padding: 1.875rem 1.25rem;
            }
            h3 {
              font-weight: 600;
              color: #002d72;
            }
            ul {
              /*&:first-child {
                                @media screen and (min-width: 768px) {
                                    margin-right: 2rem;
                                }
                            }*/
              display: flex;
              flex-wrap: wrap;
              li {
                font-family: Nunito;
                font-weight: bold;
                font-size: 0.875rem;
                color: #702082;
                display: flex;
                align-items: center;
                margin-top: 0.5rem;
                flex: 0 0 100%;
                @media screen and (min-width: $break-sm) {
                  flex: 0 0 calc(50% - 0.5rem);
                  &:nth-child(odd) {
                    margin-right: 0.5rem;
                  }
                }
                /*&:not(:first-child) {
                                    margin-top: .8rem;
                                }*/
                span:not(.badge) {
                  font-family: BebasNeue;
                  font-size: 1.125rem;
                  margin-right: 0.8125rem;
                }
                &.pick-badge {
                  color: #f4a800;
                  span:not(.badge) {
                    color: #f4a800;
                  }
                  span.badge {
                    font-family: GamjaFlower;
                    font-weight: normal;
                    font-size: 1.125rem;
                    line-height: 22px;
                    color: #f4a800;
                    @media screen and (min-width: 768px) {
                      margin-left: 0.8125rem;
                    }
                  }
                }
              }
            }

            .user-info-img {
              border-radius: 50%;
              max-width: 7.5rem;
              max-height: 9.75rem;
              @media screen and (min-width: 768px) {
                margin-right: 1.375rem;
                img {
                  margin-right: 0;
                }
              }
            }

            img {
              max-width: 7.5rem;
              max-height: 9.75rem;
              @media screen and (min-width: 768px) {
                margin-right: 1.375rem;
              }
            }
          }
        }
      }
    }

    .form-group {
      & > label {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 2.375rem;
        color: #002d72;
        margin: 0;
      }

      & > select {
        max-width: 25rem;
        background-image: linear-gradient(45deg, transparent 50%, white 50%),
          linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, #00c7b1, #00c7b1);
      }
    }
  }
}

// Start modal Video
.modal-video {
  .modal-dialog {
    margin: auto;
  }
}
