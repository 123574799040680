@import "../core/variables";
.member-singup {
  .inf-section {
    .inf-section-banner {
      align-items: flex-start;
      padding: 2rem 4.5rem;
      h4 {
        font-weight: normal;
      }
      .step-item {
        display: flex;
        align-items: center;
        justify-content: center;
        &.lock {
          .step-number:not(.locked-icon) {
            display: none;
          }
          .locked-icon {
            background-color: $c-white;
            i {
              display: flex;
              font-size: 1.3rem;
              color: #002d72;
            }
          }
        }
        &.check {
          display: flex;
          align-items: center;
          justify-content: center;
          .step-number:not(.check-icon) {
            display: none;
          }
          .check-icon {
            background-color: $c-white;
            i {
              display: flex;
              font-size: 1.3rem;
              color: #002d72;
            }
          }
        }
        .step-number {
          background-color: $c-bright-blue;
          color: $c-white;
        }
        &.collapsed {
          .step-number {
            background-color: #ffffff;
            color: #002d72;
          }
        }
      }
    }
    .inf-section-content {
      padding: 2rem 2.5rem;
      .content {
        h3 {
          font-family: BebasNeue;
          font-size: 3rem;
          line-height: 3rem;
        }
        ul {
          margin-left: 2rem;
          margin-top: 1rem;
          li {
            display: list-item;
            list-style-type: disc;
          }
        }
      }

      form.step-1 {
        .row-group:last-child {
          margin-bottom: 0;
        }
      }
      .row-group {
        margin-bottom: 4rem;
      }
      // Start Profile Form
      .profile-form {
        border-top: 12px #fff solid;
        border-bottom: 12px #fff solid;
        background-color: #fafdff;
        label {
          color: $c-navy;
          font-weight: 600;
          line-height: 1;
          font-size: 1rem;
        }
        h5 {
          font-family: Nunito;
          font-size: 1.3125rem;
          color: $c-blue;
          margin-bottom: 1rem;
        }
        .input-pj-group.one-row-group {
          min-width: unset;
          margin-top: 1.25rem;
        }
        .custom-checkbox {
          font-family: Nunito;
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 1.8rem;
          /* identical to box height */
          color: $c-navy;
          font-weight: 600;
          display: block;
          position: relative;
          padding-left: 2.1875rem;
          margin-bottom: 1.25rem;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          /* Hide the browser's default checkbox */
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 28px;
            width: 28px;
            background: #ffffff;
            border: 1px solid #c7c7c7;
            box-sizing: border-box;
            border-radius: 3px;
          }
          /* On mouse-over, add a grey background color */
          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
          /* Show the checkmark when checked */
          input:checked ~ .checkmark:after {
            display: block;
          }
          /* Style the checkmark/indicator */
          .checkmark:after {
            left: 9px;
            top: 5px;
            width: 8px;
            height: 12px;
            border: solid #929292;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        @media screen and (max-width: 1199px) {
          .input-pj-group {
            min-width: unset;
          }
        }
        @media screen and (max-width: 767px) {
          .input-pj-group {
            margin-top: 1.25rem;
          }
        }
      }
      // Start Verification Form
      .verification {
        &-head {
          h5 {
            font-family: Nunito;
            font-size: 1.3125rem;
            color: $c-blue;
          }
        }
        &-desc {
          margin-bottom: 1rem;
          @media screen and (max-width: 767px) {
            br {
              display: none;
            }
          }
        }

        label {
          color: $c-navy;
          font-weight: 600;
          line-height: 1;
          font-size: 1rem;
        }
        .input-pj-group {
          min-width: unset;
        }
        .input-date {
          input {
            background-image: url("../img/calendar-blue.svg");
            background-size: 31px 24px;
            background-repeat: no-repeat;
            background-position-x: 95%;
            background-position-y: center;
          }
          @media screen and (max-width: 767px) {
            margin-top: 1rem;
          }
        }
        &-info {
          margin-top: 2rem;
          h5 {
            font-family: Nunito;
            font-weight: 600;
            font-size: 1.35rem;
            color: $c-dark-blue;
            line-height: 1.3;
          }
        }
        .verification-toggle {
          height: unset;
          position: relative;
          small {
            position: absolute;
            right: 0;
            left: 0;
            text-align: center;
            bottom: -2rem;
            display: block;
          }
          p {
            color:#57b1f9;
          }
          p.active {
            color: #fff;
          }
          .mode {
            padding: 0.2rem 1.5rem;
          }
          @media screen and (min-width: 768px) and (max-width: 1199px) {
            small {
              right: 0;
              left: unset;
              width: 50%;
              bottom: -3.8rem;
              text-align: left;
            }
          }
          @media screen and (max-width: 767px) {
            margin-left: 0;
            margin: 2rem 0 2.8125rem 0;
            small {
              bottom: -2.5rem;
            }
          }
          @media screen and (max-width: 575px) {
            .mode {
              padding: 0.45rem 1rem;
              font-size: 1.125rem;
            }
          }
        }
      }
      // Start Note
      .note {
        background-color: #fafdff;
        border-top: 12px #fff solid;
        p {
          color: $c-bright-blue;
          font-weight: 600;
        }
      }
      // Start Step 2
      .childrens-block {
        border-top: 12px #fff solid;
        background-color: #fafdff;
        @media screen and (max-width: 575px) {
          padding: 1rem !important;
        }
        .actions {
          margin-top: 3.125rem;
        }
        .card {
          border: 0;
          background: none;
          &-body {
            border: 2px solid #002d72;
            margin-top: -1.2rem;
            background: #fff;
          }
        }
        .card-header {
          background: #002d72;
          border: 0;
          border-radius: 4px;
          margin-bottom: 1rem;
          @media screen and (max-width: 575px) {
            padding: 0.9rem 0.9rem;
          }
          span.circle {
            display: block;
            width: 2.1875rem;
            height: 2.1875rem;
            border-radius: 50%;
            background-color: #0072ce;
            padding: 0.25rem;
          }
          div {
            .btn-link {
              font-family: Nunito;
              font-style: normal;
              font-weight: 600;
              font-size: 1.3125rem;
              line-height: 1.8125rem;
              color: #ffffff;
              width: 100%;
              &:hover {
                text-decoration: none;
              }
              &:focus {
                text-decoration: none;
              }
            }
          }
        }
        .card-body {
          .profile-form {
            background-color: #fff;
          }
          .row-group {
            .input-pj-group {
              margin-top: 1.25rem;
            }
          }
        }
      }
      // Start Step 3
      .step-3 {
        button[type="submit"] {
          padding-left: 5.25rem;
          padding-right: 5.25rem;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }
      // End Step 3
      // Start Step 4
      .privacy-agree {
        margin-bottom: 8.4375rem;
        .grey {
          background-color: #828282;
        }
      }
      form.step-4 {
        .input-pj-group {
          color: #929292;
          &:not(:first-child) {
            margin-top: 1.25rem;
          }
        }
      }
      // Start Terms
      .terms {
        margin-top: 2.5rem;
        &-head {
          margin-bottom: 0.9375rem;
        }
        &-scroll {
          font-family: Nunito;
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 1.625rem;
          color: #555555;
          height: 25rem;
          background: #ffffff;
          border: 1px solid #c7c7c7;
          box-sizing: border-box;
          border-radius: 5px;
          padding-left: 0.875rem;
          padding-right: 2.5rem;
          // Custom Scroll Bar
          .simplebar-track.simplebar-vertical {
            background: #e6eaec;
            width: 1.0625rem;
            border-radius: 8.5px;
            .simplebar-scrollbar {
              background: #b8b8b8;
              border-radius: 8.5px;
              width: 1.0625rem;
              right: 0;
              &:before {
                background-color: #b8b8b8;
              }
            }
          }
        }
        &-accept {
          margin-top: 1.25rem;
        }
      }
      #collapseFour {
        button[type="button"] {
          padding-left: 5.25rem;
          padding-right: 5.25rem;
        }
      }
      // End Step 4
      #collapseFive {
        button.success-btn {
          padding-left: 5.25rem;
          padding-right: 5.25rem;
        }
      }
      // Start Success Section
      .success-section {
        background: #fafdff;
        border-top: 12px solid #ffffff;
        padding: 5.3125rem 2rem;
        .next-item {
          margin-top: 3.75rem;
          &-icon {
            margin-bottom: 2.5rem;
          }
          &-text {
            min-height: 3.625rem;
            margin-bottom: 1.875rem;
            h5 {
              font-family: Nunito;
              font-style: normal;
              font-weight: 600;
              font-size: 1.3125rem;
              text-align: center;
              color: #002d72;
              line-height: 1.8125rem;
            }
            p {
              font-weight: normal;
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .inf-section {
      flex-direction: column;
      h4 {
        font-size: 3rem;
      }
      .inf-section-banner {
        width: 100%;
      }
      .inf-section-content {
        width: 100%;
      }
    }
  }
}

.p-2-rem {
  padding: 2rem !important;
}

.pt-2-rem {
  padding-top: 2rem !important;
}

.pb-2-rem {
  padding-bottom: 2rem !important;
}

.pr-2-rem {
  padding-right: 2rem !important;
}

.pl-2-rem {
  padding-left: 2rem !important;
}

.py-2-rem {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px-2-rem {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.error-message-pj .validation-summary-errors {
  ul {
    margin: 0 !important;
    li {
      list-style-type: none !important;
      display: block !important;
    }
  }
}
#email-delivery {
  display: none;
}
.field-validation-error.error-pj-checkbox {
  background: none;
  color: #e03c31;
  padding: 0.375rem 0;
}

.challenge-question {
  margin: 1rem 0;
  color: #0072ce;
  font-weight: 600;
  line-height: 1;
  font-size: 1rem;
}
.challenge-answers {
  & > label {
    margin: 0.55rem;
  }
}
