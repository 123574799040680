.section-border {
  border-top: 6px solid $c-white;
  border-bottom: 6px solid $c-white;
  border-left: 12px solid $c-white;
  border-right: 12px solid $c-white;
  @media screen and (min-width: $break-md) {
    &.no-border-left {
      border-left: none;
    }
    &.no-border-right {
      border-right: none;
    }
  }
  @media screen and (min-width: 769px) {
    &.no-sm-border-left {
      border-left: none;
    }
    &.no-sm-border-right {
      border-right: none;
    }
  }
}

.page-header {
  background-image: url("../img/page-header-kids.svg");
  padding: 7rem 0 4rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  text-align: center;
  border-bottom: 6px solid $c-white;
  h1 {
    color: $c-white;
  }
  @media screen and (min-width: $break-sm) {
    padding: 9rem 0 4rem;
    background-image: url("../img/page-header-kids@x1.svg");
  }
  @media screen and (min-width: $break-md) {
   
    background-image: url("../img/page-header-kids@x2.svg");
  }
}

.parent {
  .page-header {
    background-image: url("../img/page-header-parent.svg");
    h1 {
      color: $c-dark-blue;
      font-weight: 700;
    }
    @media screen and (min-width: $break-sm) {
      background-image: url("../img/page-header-parent@x1.svg");
    }
    @media screen and (min-width: $break-md) {
      background-image: url("../img/page-header-parent@x2.svg");
    }
  }
}

.page-header-big {
  &-bg {
    background-image: url("../img/page-header-kids-big.svg");
    padding: 9rem 0 0;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    text-align: center;
    background-color: $c-blue;
    border-bottom: 6px solid $c-white;

    @media screen and (min-width: $break-sm) {
      background-image: url("../img/page-header-kids-big@x1.svg");
    }

    @media screen and (min-width: $break-md) {
      background-image: url("../img/page-header-kids-big@x2.svg");
    }

    h1 {
      color: $c-white;
      font-family: Nunito;
      font-size: 1.75rem;
      line-height: 2.375rem;
      text-align: center;
      margin: 1rem 1rem 2rem;
      @media screen and (min-width: $break-sm) {
        text-align: left;
        margin: 0 0 0 2rem;
      }
      @media screen and (min-width: $break-lg) {
        font-size: 3rem;
        line-height: 4rem;
      }
    }
  }

  &-inner {
    display: flex;
    flex-direction: column;
    position: relative;
    @media screen and (min-width: $break-sm) {
      flex-direction: row;
    }
    @media screen and (min-width: $break-md) {
      padding: 0 2rem;
    }
  }

  &-img {
    align-self: center;
    z-index: 2;
    max-width: 30%;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.5);
    @media screen and (min-width: $break-md) {
      align-self: flex-end;
      margin-bottom: -24px;
    }
  }

  &-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  &-title {
    @media screen and (min-width: $break-sm) {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  &-bar {
    display: flex;
    position: relative;
    z-index: 1;
    background-color: $c-dark-blue;
    justify-content: flex-start; //center;
    @media screen and (min-width: $break-sm) {
      align-items: flex-end;
      background-color: transparent;
      justify-content: flex-start;
    }
    @media screen and (min-width: $break-md) {
      /*align-items: center;*/
    }
    .blog-post-date,
    .blog-post-by,
    .blog-post-tags {
      z-index: 1;
    }
    @media screen and (min-width: $break-md) {
      .blog-post-date {
        margin-bottom: -24px;
      }
    }

    .blog-post-bottom {
      position: relative;
      align-content: center;
      padding: 1.25rem;
      @media screen and (min-width: $break-sm) {
        &:before {
          content: "";
          background-color: $c-dark-blue;
          position: absolute;
          height: 100%;
          width: 200vw;
          left: -100vw;
          z-index: 0;
          // margin-top: -20px;
        }
      }
    }
  }
}

.parent {
  .page-header-big-bg {
    background-image: url("../img/page-header-parent-big.svg");
    background-color: $c-light-blue;
    h1 {
      color: $c-dark-blue;
    }
    @media screen and (min-width: $break-sm) {
      background-image: url("../img/page-header-parent-big@x1.svg");
    }
    @media screen and (min-width: $break-md) {
      background-image: url("../img/page-header-parent-big@x2.svg");
    }
  }
}

.burst-bg {
  background-image: url(../img/burst-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.white-bg {
  background-color: $c-white;
  h4 {
    color: $c-blue;
  }
}

.light-blue-bg {
  background-color: $c-light-blue;
  h4 {
    color: $c-dark-blue;
  }
}

.bright-blue-bg {
  background-color: $c-bright-blue;
  h4 {
    color: $c-white;
  }
}

.dark-blue-bg {
  background-color: $c-dark-blue;
  h4 {
    color: $c-white;
  }
}

.blue-bg {
  background-color: $c-blue;
  h4 {
    color: $c-white;
  }
}

.dark-orange-bg {
  background-color: $c-dark-orange;
}

.orange-bg {
  background-color: $c-orange;
}

@media screen and (max-width: 767px) {
  .full-width-sm .container {
    max-width: none;
  }
  .page-header-big-bg .container,
  .page-header-big-bg .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .full-width-md .container {
    max-width: none;
  }
}

.c-btn.btn-load-more,
.c-btn.btn-load-more-activities {
  margin: 0 auto;
  display: block;
  border-radius: 5px;
  font-size: 2rem;
  padding: 0.625rem 3rem;
  max-width: max-content;
}

.text-blue {
  color: $c-blue;
}
.text-orange {
  color: $c-dark-orange;
}
.text-purple {
  color: $c-dark-purple;
}
.text-green {
  color: $c-dark-green;
}
.text-dark-blue {
  color: $c-dark-blue;
}
.text-red {
  color: $c-red;
}

.link-underline {
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: $c-yellow;
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    border-radius: 4px;

    .parent & {
      background: $c-bright-blue;
    }
  }

  &:hover:before {
    right: calc(51% - 15px);
    left: calc(51% - 15px);
  }
}
