.top-menu {
  background-color: $c-dark-blue;
  .top-menu-cont {
    display: flex;
    justify-content: flex-end;
    padding: 0.375rem 0;
  }
  .books-counter {
    margin: 0 0.5rem;
  }
}

.user-options {
  position: relative;
  &-inner {
    position: relative;
    color: $c-white;
    display: flex;
    padding: 0.375rem 2.25rem 0.375rem 0.625rem;
    border-radius: 2rem;
    background-color: $c-blue;
    cursor: pointer;
    z-index: 99999;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 18px;
      margin: auto 0;
      height: 0px;
      width: 3px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid $c-white;
    }
  }
  .avatar {
    margin-right: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    img {
      //max-height: 85%;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
    }
    /*&.kids {
      overflow: hidden;
      align-items: flex-end;
    }*/
  }
  .user-options-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 1.25rem;
    left: 0;
    width: 100%;
    background-color: $c-blue;
    z-index: 9999;
    border-radius: 0px 0px 1rem 1rem;
    padding-top: 1rem;
    .user-options-item {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
      border-left: 5px solid $c-blue;
      a {
        display: block;
        padding: 0.8125rem 1rem;
        color: $c-white;
      }
      input[type="submit"] {
        border: none;
        background: transparent;
        color: #fff;
        display: block;
        padding: 0.8125rem 1rem;
      }
      &:hover {
        border-left: 5px solid $c-yellow;
        background-color: #187fd1;
        .parent & {
          border-left: 5px solid $c-bright-blue;
        }
      }
      &:last-child {
        border-radius: 0 0 1rem 1rem;
      }
    }
  }
  &:hover {
    border-radius: 1rem 1rem 0 0;
    .user-options-menu {
      display: flex;
    }
  }

  .parent-avatar {
    display: none;
    .parent & {
      display: flex;
    }
  }
  .kid-avatar {
    display: flex;
    .parent & {
      display: none;
    }
  }
}

.togle-wraper {
  margin-left: 2.5rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  @media screen and (min-width: $break-lg) {
    margin-right: 2.5rem;
  }
  .mode {
    border-radius: 3rem 0 0 3rem;
    padding: 0.125rem 1.5rem;
    background-color: $c-bright-blue;
    font-family: BebasNeue;
    font-size: 1.5rem;
    color: $c-white;
    .tablet-sidebar-menu & {
      padding: 0.438rem 2.2rem;
    }
  }
  .togle-swicher {
    padding: 0.5rem 0;
    border-radius: 0 3rem 3rem 0;
    background-color: $c-dark-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    .tablet-sidebar-menu & {
      padding: 0.813rem 1.5rem;
    }
    p {
      color: $c-bright-blue;
      padding: 0 0.75rem;
      @media screen and (min-width: $break-lg) {
        padding: 0 1.25rem;
      }
      &.active {
        color: $c-white;
      }
    }
  }
}

.search-input-box,
.search-input-box-2 {
  input {
    border: none;
    border-radius: 2rem 0 0 2rem;
    font-family: Nunito;
    font-size: 1rem;
    color: $c-dark-blue;
    padding: 0.625rem 1rem;
  }
  button {
    border: none;
    border-radius: 0 2rem 2rem 0;
    font-family: Nunito;
    font-size: 1rem;
    i {
      vertical-align: middle;
    }
  }
}

.books-counter,
.achiv-counter {
  display: flex;
  justify-content: center;
  min-width: 3.125rem;
  align-items: center;
  i {
    font-size: 1.5rem;
  }
  span {
    font-family: BebasNeue;
    font-weight: bold;
    font-size: 1.5rem;
    margin-left: 10px;
  }
}

.books-counter {
  margin-bottom: 8px;
}

.header-base {
  background-color: rgba(0, 45, 114, 0.75);
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;

  .parent & {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 2px 4px rgba(0, 45, 114, 0.4);
  }

  @media screen and (min-width: $break-lg) {
    position: absolute;
    top: auto;
  }

  .logo-holder {
    background-image: url("../img/tag.png");
    width: 6.125rem;
    position: relative;
    top: -2px;
    align-self: flex-start;
    z-index: 99999;
    display: block;
    padding: 0.75rem 0.5rem 1.25rem 0.5rem;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 5px 0px 6px -5px rgba(0, 45, 114, 0.4), -5px 0px 8px -5px rgba(0, 45, 114, 0.4);
    margin-right: 1rem;
    @media screen and (min-width: $break-lg) {
      width: 9.75rem;
      padding: 1rem 1rem 2.5rem 1rem;
    }
  }

  @media screen and (max-width: 1199px) {
    .parent & {
      background-color: #e6eff7 !important;
    }
    .tablet-sidebar {
      top: 3.5rem;
      box-shadow: 0px -2px 5px -4px rgba(0, 45, 114, 0.4);
      .tablet-sidebar-menu {
        .mobile-menu {
          background-color: transparent;
        }
        .login-btn {
          background: rgba(0, 31, 79, 1);
          .parent & {
            background: transparent;
          }
        }
        .togle-wraper {
          padding: 3rem 1rem;
          margin-left: 0;
        }
        .menu-public-options {
          padding: 0;
        }
        .search-input-box-wrapper {
          background-color: #001f4f;
          width: 100%;
          .search-input-box,
          .search-input-box-2 {
            padding: 2rem 1rem;
          }
        }
      }
    }
  }
  &.bg-header-light {
    background-color: #fff;
    .header-nav-menu .header-nav-item .header-nav-item-link {
      color: $c-dark-blue;
    }
  }

  .header-cont {
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: space-between;
    height: 3.5rem;
    @media screen and (min-width: $break-lg) {
      height: 5.5rem;
    }
    .login-btn {
      display: flex;
      align-items: center;
      margin-left: auto;
      .c-btn {
        font-size: 1.5rem;
        padding: 0.5rem 1.5rem;
      }
      &.btn-group.signup-signin {
        @media screen and (min-width: $break-sm) {
          .c-btn {
            padding: 0.25rem 1rem;
          }
        }
        @media screen and (min-width: $break-lg) {
          .c-btn {
            padding: 0.5rem 1.5rem;
          }
        }
        a:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        a:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    @media screen and (min-width: $break-sm) {
      .header-nav {
        flex-grow: 1;
        height: 100%;
        .main-nav {
          display: flex;
          height: 100%;
          .login-btn {
            order: 2;
          }
        }
      }
    }

    .search-toggle {
      display: flex;
      align-items: center;
      &.active {
        .search-input-box {
          display: flex;
        }
      }
      .search-toggle-btn {
        cursor: pointer;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        background-color: $c-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $c-white;
        font-size: 1.25rem;
        line-height: 1;
        i {
          display: flex;
        }
      }
      .search-input-box,
      .search-input-box-2 {
        position: absolute;
        top: 0px;
        right: 0;
        min-width: 400px;
        background-color: #001f4f;
        padding: 1.455rem 1.5rem 1.455rem 3.25rem;
        display: none;
        input {
          width: 60%;
        }
        button {
          width: 30%;
        }
        span {
          top: -2px;
          left: 8px;
          position: absolute;
          color: $c-white;
          font-size: 1.25rem;
          font-weight: 400;
          cursor: pointer;
          &:hover {
            color: $c-blue;
          }
        }
      }
    }
  }
  .header-nav-menu {
    display: flex;
    height: 100%;
    position: relative;
    align-items: center;
    .header-nav-item {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      .header-nav-item-link {
        font-family: BebasNeue;
        font-size: 1.5rem;
        font-weight: 600;
        padding: 0 0.625rem;
        color: $c-white;
        width: 100%;
        position: relative;
        @media screen and (min-width: $break-lg) {
          font-size: 1.875rem;
        }

        .parent & {
          color: $c-dark-blue;
        }

        @media screen and (max-width: 767px) {
          &:before {
            height: 0;
          }
        }
      }
      .header-nav-drop-menu {
        display: none;
        position: absolute;
        flex-direction: column;
        left: 50%;
        top: 3.5rem;
        z-index: 9999;
        transform: translateX(-50%);
        @media screen and (min-width: $break-lg) {
          top: 5.5rem;
        }
        &:before {
          content: "";
          position: absolute;
          top: -7px;
          left: 0px;
          right: 0px;
          margin: 0 auto;
          height: 0px;
          width: 3px;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 7px solid $c-white;
          .parent & {
            border-bottom: 7px solid $c-dark-blue;
          }
        }
        .header-nav-drop-item {
          background-color: $c-white;
          font-size: 1rem;
          font-weight: 900;
          padding: 1rem;
          color: $c-blue;
          white-space: nowrap;
          border-top: 1px solid rgba(0, 45, 114, 0.1);
          border-left: 1px solid rgba(0, 45, 114, 0.1);
          border-left: 4px solid $c-white;
          .parent & {
            background-color: $c-dark-blue;
            border-left: 4px solid $c-dark-blue;
            border-top: solid 1px rgba(255, 255, 255, 0.1);
          }
          .header-nav-drop-link {
            color: $c-blue;
            display: block;
            .parent & {
              color: $c-white;
            }
          }
          &:hover {
            background-color: #e5eaf0;
            border-left: 4px solid $c-yellow;
            .parent & {
              background-color: #003f89;
              border-left: 4px solid $c-bright-blue;
            }
          }
          &:first-child {
            border-top: none;
          }
          &:last-child {
            border-radius: 0 0 1rem 1rem;
          }
        }
      }
      &:hover {
        .header-nav-drop-menu {
          display: flex;
        }
      }
    }
  }
  .header-nav-mobile {
    margin-left: 1rem;
    .header-nav-item {
      &-link {
        line-height: normal;
        align-self: flex-end;
      }
      &.collapsed {
        .burger-icon {
          display: block;
        }
        .close-icon {
          display: none;
        }
      }
    }
  }
  .burger-icon {
    color: $c-white;
    font-size: 1rem;
    align-items: center;
    display: none;
    cursor: pointer;
    i {
      display: flex;
      line-height: normal;
    }
    .parent & {
      color: $c-dark-blue;
    }
  }
  .close-icon {
    display: block;
    color: $c-white;
    font-size: 1rem;
    font-weight: bold;
    align-items: center;
    // line-height: 18.2px;
    // align-self: flex-end;
    cursor: pointer;
    .parent & {
      color: $c-dark-blue;
    }
    i {
      display: flex;
      line-height: normal;
    }
  }
}

.tablet-sidebar {
  position: fixed;
  top: 5.5rem;
  bottom: 0px;
  overflow: auto;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  z-index: 999;
  .tablet-sidebar-menu {
    position: absolute;
    right: 0;
    min-width: 320px;
    background-color: rgba(0, 31, 79, 0.95);
    z-index: 1000;
    min-height: calc(100vh - 3.5rem);
    .parent & {
      background-color: rgba(255, 255, 255, 0.95);
    }
    .login-btn {
      padding: 1.5rem 1.5rem;
    }
    .top-menu-cont {
      background-color: $c-dark-blue;
      padding: 1.5rem 1.5rem 1.5rem 1rem;
      .user-options {
        &-menu {
          display: flex;
          flex-direction: row;
          position: relative;
          top: -1rem;
          &.collapse:not(.show) {
            display: none;
          }
        }
        &-item {
          border-left: none;
          .parent & {
            border-left: none;
          }
          &:hover {
            background-color: inherit;
          }
          &:first-child {
            border-radius: 0 0 0 1rem;
          }
        }
      }
    }
    .togle-wraper {
      margin-right: 0;
    }
    .mobile-menu {
      background-color: rgba(0, 45, 114, 0.95);
      .parent & {
        // background-color: rgba(255, 255, 255, 0.95);
        background-color: transparent;
      }
      .header-nav-menu {
        flex-direction: column;
        align-items: flex-start;
        .header-nav-item {
          width: 100%;
          flex-direction: column;
          &:first-child {
            .header-nav-item-link {
              border-top: solid 1px rgba(255, 255, 255, 0.1);
              .parent & {
                border-top: 1px solid rgba(0, 45, 114, 0.1);
              }
            }
          }
          .header-nav-item-link {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            border-left: 5px solid transparent;
            font-size: 2.5rem;
            font-weight: bold;
            padding: 0.5rem 1.5rem;
            line-height: 1;
            .parent & {
              border-bottom: 1px solid rgba(0, 45, 114, 0.1);
            }
          }
          &:hover {
            .header-nav-item-link {
              border-left: 5px solid $c-yellow;
              .parent & {
                border-left: 5px solid $c-bright-blue;
              }
            }
            .header-nav-drop-menu {
              display: block;
            }
          }
          .header-nav-drop-menu {
            position: initial;
            transform: none;
            width: 100%;
            &:before {
              top: 3.125rem;
              right: unset;
              left: 1.75rem;
            }
            .header-nav-drop-item {
              border-radius: 0px !important;
              border-left: none;
            }
          }
        }
      }
    }
    .search-input-box,
    .search-input-box-2 {
      display: flex;
      justify-content: center;
      input {
        width: 100%;
      }
    }
    .menu-public-options {
      padding: 2rem 1rem;
    }
  }
}

@media (max-width: 576px) {
  .tablet-sidebar-menu {
    width: 100% !important;
    .top-menu-cont {
      padding-top: 50px !important;
    }
  }
}

.switcher-tooltip {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  border-radius: 0.5rem;
  position: absolute;
  z-index: 1;
  top: 3.625rem;
  left: 4rem;
  padding: 0.5rem 1.5rem;
  &:before {
    content: "";
    position: absolute;
    top: -1.75rem;
    left: calc(50% - 10px);
    border-width: 0.875rem;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.5) transparent;
  }
}

.kid-mode {
  display: inline;
}
.parent-mode {
  display: none;
}

.parent {
  .kid-mode {
    display: none;
  }
  .parent-mode {
    display: inline;
  }
}

.top-menu-cont .btn-impersonate.c-btn {
  padding: 0.25rem 1rem;
  line-height: 1.125;
}
