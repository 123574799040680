.parent-guide-header {
    .return-to {
        position: absolute;
        bottom: -20%;
        left: 0;
        right: 0;
        margin: auto;
        h4 {
            font-weight: 600;
            color: #002d72;
        }
    }
    a.book-preview-link {
        @media screen and (min-width: 992px) {
            background-color: #702082;
        }
        @media screen and (min-width: 768px) {
            border-bottom-color: #702082;
        }
        background-color: transparent;
        color: #fff;
    }
    .blog-post-tags,
    .blog-post-by {
        a {
            position: relative;
            display: inline-block;
            &:hover {
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: #fff;
                }
            }
        }
    }
    .blog-post-bottom i.icon {
        color: #57b1f9;
    }
}

.book-summary {
    &.parent-guide-book-summary {
        a.c-btn {
            &:not(:last-child) {
                margin-right: 1.25rem;
            }
        }
        .book-options {
            .book-option {
                span {
                    font-size: 0.9rem;
                    color: #002d72;
                    font-weight: 600;
                }
                color: #555555;
                font-weight: normal;
            }
        }
        .book-raiting-summary h4 {
            font-weight: 600;
        }
    }
}

// Start Age range
.age-range {
    z-index: 1;
    &-title {
        p {
            font-family: Nunito;
            font-size: 1.3125rem;
            font-weight: 600;
            color: #fff !important;
        }
        margin-right: 10px;
    }
    .progress {
        width: 16.75rem;
        background-color: #A9A9A9;
        border-radius: 33.5px;
        height: 22px;
        overflow: visible;
        position: relative;
        .circle {
            font-family: BebasNeue;
            font-weight: bold;
            font-size: 1.2rem;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -0.625rem;
            bottom: 0;
            background-color: #009484;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            z-index: 1;
            text-align: center;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        }
        &-bar {
            background-color: #00c7b1;
            width: 50%;
            border-bottom-left-radius: 33.5px;
            border-top-left-radius: 33.5px;
        }
    }
}

// Start Book Advantage
.book-guide-advantage {
    .advantage-item {
        border: 6px solid #FFFFFF;
        text-align: center;
        padding: 35px 35px;
        &:first-child {
            border-left: 12px solid #FFFFFF;
        }
        &:last-child {
            border-right: 12px solid #FFFFFF;
        }
        // width: 18rem;
        &:first-child {
            background-color: $c-bright-blue;
        }
        &:nth-child(2) {
            background-color: $c-blue;
        }
        &:last-child {
            background-color: $c-dark-blue;
        }
    }
    .advantage-item-title {
        h3 {
            font-family: BebasNeue;
            font-weight: normal;
            font-size: 2.625rem;
            line-height: 2.625rem;
            color: #fff;
        }
        @media screen and (max-width: 1199px) {
            h3 {
                font-size: 2rem;
            }
        }
    }
    .advantage-item-icons {
        ul {
            display: flex;
            justify-content: center;
            li {
                display: list-item;
                i {
                    color: #fff;
                    font-size: 2.5rem;
                    @media screen and (max-width: 1199px) {
                        font-size: 1.3rem;
                    }
                }
            }
            li:not(:last-child) {
                padding-right: 2rem;
            }
        }
    }
}

// Start Book Guide Accordion
.book-guide {
    // Start Desktop Styles
    .steps-list {
        background-color: $c-dark-blue;
        width: 33%;
        padding: 1.5rem 1.5rem;
        min-height: 40.625rem;
        &-title {
            h3 {
                font-family: BebasNeue;
                font-weight: normal;
                font-size: 2.625rem;
                line-height: 2.625rem;
                color: #fff;
            }
        }
        .step-item {
            &:first-child {
                margin-top: 0;
            }
            ul {
                margin-left: 1rem;
                li {
                    font-size: 1.2rem;
                    &:not(:last-child) {
                        margin-right: .6rem;
                    }
                    i {
                        color: #00c7b1;
                    }
                }
            }
            .step-number {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                i {
                    font-size: 1.3rem;
                    color: $c-bright-blue;
                    display: flex;
                }
            }
            &[aria-expanded="true"] {
                .step-number {
                    background-color: $c-bright-blue;
                    i {
                        color: #fff;
                    }
                }
            }
        }
    }
    .stepper {
        .steps-content {
            width: 67%;
        }
    }
    .collapse-text {
        ul {
            display: list-item;
            margin-left: 1.2rem;
            li {
                display: list-item;
                font-size: 1rem;
                list-style: disc;
                &:not(:last-child) {
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
    // Start Tablet Styles
    .stepper-tablet {
        .step-item {
            .step-number {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                i {
                    font-size: 1.3rem;
                    color: $c-bright-blue;
                    display: flex;
                }
            }
            &[aria-expanded="true"] {
                .step-number {
                    background-color: $c-bright-blue;
                    i {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.stepper-tablet {
    .actv-item .actv-item-cont .collapse-title {
        text-align: center;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h4 {
            font-size: 1.5rem;
            color: #002d72;
        }
    }
    ul.icons-group {
        @media screen and (min-width: 576px) {
            margin-left: 1rem;
        }
        @media screen and (min-width: 768px) {
            margin-left: 2rem;
        }
        li {
            // line-height: normal;
            font-size: 1.5rem;
            &:not(:last-child) {
                margin-right: 1.5rem;
            }
            i {
                color: #00c7b1;
            }
        }
    }
}

.stepper .steps-content.steps-content-bookguide {
    padding: 3rem 1.5rem;
    .actv-item .actv-item-cont h4 {
        color: #002d72;
        font-weight: 600;
    }
    ul.icons-group {
        margin-left: 2rem;
        li {
            // line-height: normal;
            font-size: 3rem;
            &:not(:last-child) {
                margin-right: 1.5rem;
            }
            i {
                color: #00c7b1;
            }
        }
    }
}