.member-nav {
  @mixin member-nav-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;
    z-index: 3;
    margin-left: auto;
    margin-right: auto;
    transform-style: preserve-3d;
    &:after {
      content: "";
      position: absolute;
      right: 3.22%;
      bottom: -2.62%;
      border-radius: 50%;
      z-index: -1;
      transform: translateZ(-1px);
    }
    @media screen and (max-width: 767px) {
      width: 9.25rem;
      height: 9.25rem;
      &:after {
        width: 9.25rem;
        height: 9.25rem;
      }
      .member-nav-item-icon {
        img {
          max-width: 35%;
        }
      }
    }
    @media screen and (min-width: 768px) {
      width: 10.1875rem;
      height: 10.1875rem;
      margin-top: 2.1875rem;
      margin-bottom: 2.1875rem;
      &:after {
        width: 10.1875rem;
        height: 10.1875rem;
      }
      .member-nav-item-icon {
        img {
          max-width: 40%;
        }
      }
    }
    @media screen and (min-width: 992px) {
      width: 13rem;
      height: 13rem;
      margin-top: 2.1875rem;
      margin-bottom: 2.1875rem;
      &:after {
        width: 13rem;
        height: 13rem;
      }
    }
    @media screen and (min-width: 1200px) {
      width: 17rem;
      height: 17rem;
      &:after {
        width: 17rem;
        height: 17rem;
      }
    }
    @media screen and (min-width: 1300px) {
      width: 18.4375rem;
      height: 18.4375rem;
      &:after {
        width: 18.4375rem;
        height: 18.4375rem;
      }
      .member-nav-item-icon {
        img {
          max-width: 100%;
        }
      }
    }
  }
  &-col {
    &.active {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: -9%;
        left: 0%;
        right: 0;
        width: 0;
        height: 0;
        margin: auto;
        border-left: 35px solid transparent;
        border-right: 35px solid transparent;
        @media screen and (max-width: 767px) {
          display: none;
        }
        @media screen and (min-width: 992px) {
          border-left: 50px solid transparent;
          border-right: 50px solid transparent;
        }
      }
      &.badges {
        &::before {
          border-bottom: 35px solid #4d1659;
          @media screen and (min-width: 992px) {
            border-bottom: 50px solid #4d1659;
          }
        }
      }
      &.bookshelf {
        &::before {
          border-bottom: 35px solid #c85408;
          @media screen and (min-width: 992px) {
            border-bottom: 50px solid #c85408;
          }
        }
      }
      &.account {
        &::before {
          border-bottom: 35px solid #002d72;
          @media screen and (min-width: 992px) {
            border-bottom: 50px solid #002d72;
          }
        }
      }
      &.activity {
        &::before {
          border-bottom: 35px solid #009484;
          @media screen and (min-width: 992px) {
            border-bottom: 50px solid #009484;
          }
        }
      }
    }
    .member-nav-item {
      @include member-nav-items;
    }
    &:nth-child(1) {
      .member-nav-item {
        background-color: #f28028;
        &:after {
          content: "";
          background: #ce5e10;
        }
      }
    }
    &:nth-child(2) {
      .member-nav-item {
        background-color: #702082;
        &:after {
          content: "";
          background: #4d1659;
        }
      }
    }
    &:nth-child(3) {
      .member-nav-item {
        background-color: #00c7b1;
        &:after {
          content: "";
          background: #009484;
        }
      }
    }
    &:nth-child(4) {
      .member-nav-item {
        background-color: #0072ce;
        &:after {
          content: "";
          background: #005b8f;
        }
      }
    }
  }
  &-title {
    margin-top: 1rem;
    h4 {
      font-family: Bangers;
      font-style: normal;
      font-weight: normal;
      font-size: 1.75rem;
      line-height: 1.875rem;
      text-align: center;
      color: #ffffff;
      @media screen and (min-width: 992px) {
        font-size: 2.45rem;
        line-height: 2.875rem;
      }
    }
  }
}

// Start Active Page Bar
.active-page-bar {
  .progress-line {
    &.bookshelf {
      background-color: #c85408;
    }
    &.badges {
      background-color: #4d1659;
    }
    &.account {
      background-color: #002d72;
    }
    &.activity {
      background-color: #009484;
    }
    height: 1.875rem;
    width: 100%;
    position: relative;
    border-left: 12px solid #fff;
    border-right: 12px solid #fff;
    @media screen and (min-width: 768px) {
      &:after {
        content: "";
        position: absolute;
        left: 8.5%;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
      }
    }
  }
}

// Start Bookshelf Info
.bookshelf-info {
  @include profileInfoSection(#f28028, #fcefe6, #c85408, #f08028, #c85408);
}

.section-list-of-books {
  @media screen and (max-width: 767px) {
    .section-content {
      padding: 0 0;
    }
    .cg-card-details {
      padding: 0;
    }
    .mobile-info {
      img {
        max-width: 9.0625rem;
      }
      .c-card-desc .c-card-title {
        font-size: 1.3125rem;
        line-height: normal;
      }
    }
    .c-card-info {
      padding: 0 1.8125rem 1rem 1.8125rem;
    }
  }
  .light-fa-bg {
    background-color: #fafafa;
  }
  .filters {
    padding-top: 3.4375rem;
    padding-bottom: 2.1875rem;
    .input-pj-group {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: 1199px) {
        min-width: unset;
      }
      @media screen and (min-width: 992px) {
        margin-top: 0;
      }
    }
  }
  .c-card-info {
    padding-top: 1.5625rem;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .img-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.3125rem;
        height: 2.3125rem;
        border-radius: 50%;
        img {
          margin: auto;
        }
        i {
          color: #fff;
        }
      }
      .statistics {
        span {
          font-family: Nunito;
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          text-align: center;
        }
      }
      &:first-child {
        .img-circle {
          background-color: #702082;
        }
        .statistics {
          span {
            color: #702082;
          }
        }
      }
      &:nth-child(2) {
        .img-circle {
          background-color: #00c7b1;
        }
        .statistics {
          span {
            color: #00c7b1;
          }
        }
      }
      &:last-child {
        .img-circle {
          background-color: #0072ce;
        }
        .statistics {
          span {
            color: #0072ce;
          }
        }
      }
    }
  }
  .cg-card-footer {
    display: flex;
    align-items: center;
    // Custom Radio
    .custom-radio {
      font-family: Nunito;
      font-weight: 600;
      /* identical to box height */
      color: #fff;
      display: block;
      position: relative;
      padding-left: 2.1875rem;
      margin-bottom: 0;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      /* Hide the browser's default checkbox */
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid #fff;
        box-sizing: border-box;
        margin-left: 0.4375rem;
      }
      /* On mouse-over, add a grey background color */
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      /* Show the checkmark when checked */
      input:checked ~ .checkmark:after {
        display: block;
      }
      /* Style the checkmark/indicator */
      .checkmark:after {
        left: 5px;
        top: 1px;
        width: 8px;
        height: 12px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    // Book read
    .book-read {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      .icon {
        margin-right: 5px;
      }
      &.without-review {
        .left {
          width: 100%;
          &:after {
            display: none;
          }
        }
      }
      .left {
        font-family: Nunito;
        font-weight: 600;
        font-size: 1rem;
        color: #fff;
        height: 100%;
        width: 8rem; // Here edit
        background-color: #78be20;
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 0.625rem;
        span {
          z-index: 3; // Here edit
        }
        &::after {
          background: #78be20 none repeat scroll 0 0;
          content: "";
          height: 100%;
          position: absolute;
          right: -8px; // Here edit
          top: 0;
          transform: skew(-20deg);
          width: 15px; // Here edit
          z-index: 1; // Here edit
        }
        img {
          padding-right: 0.625rem;
        }
      }
      .right {
        margin-left: auto;
        padding-right: 0.625rem;
        a {
          font-size: 0.8125rem;
          color: #fff;
        }
      }
      @media screen and (min-width: 992px) and(max-width: 1199px) {
        .left {
          width: 8.5rem;
          &:after {
            width: 31px;
          }
        }
      }
      @media screen and (max-width: 575px) {
        .left {
          width: 9rem;
          &:after {
            width: 1rem;
            right: -8px;
          }
        }
      }
    }
  }
}
