.custom-text-widget {
  color: #555555;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: Nunito;
    color: #555555;
    font-weight: bold;
  }

  p {
    margin: 1rem 0;
  }

  ul {
    margin: 0;
    padding: 40px;
    li {
      display: list-item;
      list-style: disc;
    }
  }
}
