.pagination {
  display: flex;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 0;
  & > li > a,
  & > li > span {
    position: relative;
    font-family: Bangers;
    font-size: 1.5rem;
    color: #0071cd;
    line-height: 1.5;
    margin-left: 0;
    float: none;
    padding: 0.25rem;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .parent & {
      font-family: BebasNeue;
    }
  }

  & > li > a:hover,
  & > li > span:hover,
  & > li > a:focus,
  & > li > span:focus {
    color: #f38026;
    text-decoration: underline;
  }
  & > .active {
    & > a,
    & > span,
    & > a:hover,
    & > span:hover,
    & > a:focus,
    & > span:focus {
      z-index: 2;
      cursor: default;
      color: #f38026;
      text-decoration: underline;

      .parent & {
        color: #fff;
        text-decoration: none;
        background: #f5a800;
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
  }

  & > .disabled {
    & > span,
    & > a,
    & > a:hover,
    & > a:focus {
      color: #a3d5d2;
      cursor: not-allowed;
    }
  }

  .PagedList-ellipses a {
    font-weight: bold;
    color: #0071cd;
    &:hover,
    &:focus {
      color: #0071cd;
    }
  }

  .PagedList-skipToNext,
  .PagedList-skipToPrevious {
    & > a {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
    }
  }
  li {
    order: 2;
  }

  li.PagedList-skipToPrevious {
    order: 1;
  }

  li.PagedList-skipToNext {
    order: 3;
  }
}
