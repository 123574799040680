.contact-page-content {
  .question-card {
    background: $c-bright-blue;
    @media screen and (min-width: $break-md) {
      border-right: 6px solid #fff;
    }
  }

  .book-card {
    background: $c-blue;
    height: 100%;
    @media screen and (min-width: $break-md) {
      border-left: 6px solid #fff;
    }
  }

  .contact-info {
    background-color: $c-dark-blue;
    height: 100%;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $break-md) {
      padding: 5rem 4.25rem;
    }

    h3 {
      font-family: BebasNeue;
      font-size: 3rem;
      line-height: 1;
      color: #fff;
      margin-bottom: 1rem;
    }
    p {
      font-family: Nunito;
      font-size: 1.25rem;
      line-height: 1.875rem;
      color: #fff;
      margin-bottom: 1rem;
    }
    ul {
      color: white;
      padding-left: 2.25rem;
      li {
        font-family: Nunito;
        font-size: 1.25rem;
        line-height: 1.75rem;
        margin-top: 30px;
        position: relative;

        i {
          color: $c-blue;
          position: absolute;
          top: 0;
          left: -32px;
        }

        a {
          color: white;
          text-decoration: white;
        }
      }
    }
  }

  .contact-form {
    background-color: $c-light-blue;
    padding: 3rem 1rem 5rem;
    height: 100%;
    @media screen and (min-width: $break-md) {
      padding: 5rem 4.25rem;
    }

    h3 {
      font-family: BebasNeue;
      font-size: 3rem;
      line-height: 1;
      color: $c-blue;
      margin: 0 0 1rem 0;
    }

    form {
      .input-pj-group {
        padding-top: 20px;
        min-width: unset;
      }

      .form-btn {
        margin-top: 12px;

        button {
          width: 170px;
          height: 49px;
          font-size: 1.875rem;
          text-align: center;
          padding: 0;
        }
      }
    }
  }
}

.info-card {
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 486px;

  @media screen and (min-width: $break-md) {
    padding: 4rem 6rem;
  }

  .info-card-text {
    margin-bottom: 1rem;

    h3 {
      color: white;
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 1.75rem;
      line-height: 2.375rem;
      margin-bottom: 0.5rem;
    }

    p {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 1.25rem;
      line-height: 30px;
      color: white;
    }
  }

  .info-card-button {
    .c-btn {
      background-color: white;
      color: #0072ce;
      min-width: 235px;
      &:hover{
        background-color: #e5eaf0;
        color: #0072ce;
      }
    }
  }
}

.form-builder .form-field {
  margin: 0 0.5rem 1rem 0;
}

.form-builder input[type="submit"] {
  width: 170px;
  font-size: 1.875rem;
  font-family: BebasNeue;
  line-height: 1;
  text-align: center;
  padding: 0.5rem;
  background-color: #57b1f9;
  color: white;
  text-transform: capitalize;
  border-radius: 25px;
  border: none;
  outline: none;
  transition-duration: 0.4s;
  float: right;
  margin-right: 1rem;
}
.form-builder input[type="submit"]:hover {
  background-color: #0072ce;
  transform: scale(1.1) !important;
}

form  [id$="-form-zone"] {
  width: 100%!important;
  @media screen and (min-width: 768px) {
      width: 50%!important;
  }
}
