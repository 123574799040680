.about-tabs {
  background-color: $c-navy;
  border-bottom: 6px solid #fff;
  margin-top: -6px;

  .tabs-row {
    display: flex;
    padding: 1.875rem 0;
    justify-content: space-around;
    flex-wrap: wrap;

    .tabs-item {
      position: relative;
      margin: 0.625rem 1rem;
      min-width: 10rem;
      text-align: center;
      line-height: 2;
      a {
        white-space: nowrap;
        font-size: 1.25rem;
        text-align: center;
        color: white;
        text-decoration: none;
      }

      &.active {
        a {
          &:after {
            content: "";
            border-bottom: 5px solid $c-bright-blue;
            display: block;
            width: 26px;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.parent .about-page-banner .c-btn {
  background-color: $c-dark-yellow !important;
}

.parent .about-page-banner .section-content {
  background-color: $c-blue !important;
}

.parent .activities .section-header-teal {
  background-color: $c-bright-blue;
}

.about-page-banner {
  
  .section-content {
    background-image: url("../img/burst-bg.svg");
    background-color: $c-purple;
    background-position: center;

    .top-banner-cont {
      position: relative;
      @media screen and (min-width: $break-md) {
        padding: 3rem 2rem 0;
      }
      p {
        margin-bottom: 1.75rem;
        font-family: Nunito;
        a {
          color: #fff;
          &:hover {
            text-decoration: underline;
          }
        }
        &.login-hint {
          font-family: GamjaFlower;
          font-size: 1.4rem;
          line-height: 1.4rem;
        }
      }

      button {
        margin-bottom: 2.25rem;
        max-width: 16.25rem;
        width: 100%;
      }
    }

    .banner-text-title {
      font-family: Bangers;
      font-size: 5.375rem;
      font-weight: normal;
      color: white;
      margin-bottom: 1.75rem;
    }
    @media (max-width: 426px) {
      .banner-text-title {
        font-size: 3.375rem;
      }
    }
  }
}

.button-type {
  margin-bottom: 2.25rem;
  max-width: 16.25rem;
  width: 100%;
  margin-top: 2rem;
}

.actv-item {
  width: 100%;

  .actv-item-icon {
    display: flex;
    justify-content: center;
    margin-bottom: 2.1875rem;
    align-items: flex-start;
    height: 110px;

    img {
      height: 100%;
    }
  }

  .actv-item-cont {
    text-align: center;

    h4 {
      font-size: 1.75rem;
      margin-bottom: 1.25rem;
      color: $c-blue;
    }

    p {
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: #555555;
      padding-bottom: 1.25rem;
      a {
        text-decoration: none;
        font-family: Nunito;
        font-weight: 600;
        max-width: 355px;
      }
    }

    a {
      &.lite-link {
        font-size: 1.3rem;
        color: $c-blue;
      }
    }

    button {
      min-width: 136px;
      max-width: 355px;

      @media (max-width: 426px) {
        padding: 0.5rem;
      }
    }
  }

  @media (max-width: 769px) {
    margin-bottom: 4.6875rem;
  }
}

.select-book-subheading {
  text-align: center;
  h4 {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 1.25rem;
    color: $c-blue;
  }

  p {
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #555555;
    padding-bottom: 1.25rem;
    @media screen and (max-width: 1348px) {
      br {
        display: none;
      }
    }
    img.select-book__badge {
      @media screen and (max-width: 575px) {
        min-width: 50px !important;
        height: 50px !important;
        width: 50px !important;
      }
    }
  }
}

.actv-item-banners {
  display: flex;

  .item-banner {
    height: 317px;
    width: 24%;
    margin: 0.375rem 0.3125rem;
    position: relative;

    img {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;
    }

    &.new {
      position: relative;

      &:after {
        content: "";
        height: 77px;
        width: 77px;
        position: absolute;
        top: -15px;
        left: -17px;
        background-position: center;
        background-image: url(../img/card-banner-new.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .item-banner {
      height: 328px;
      width: 250px;
      margin: 0.625rem 0.3125rem;

      img {
        height: 100%;
      }
    }
  }
}

.stepper {
  display: flex;
  .steps-list {
    padding: 5.125rem 4.125rem 2.75rem;
    width: 35%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
  }

  .steps-content {
    width: 65%;
    min-height: 200px;
    padding: 6rem 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    .cg-card-details {
      padding: 3.5rem 1rem 2rem;
    }
  }
}

.step-item {
  display: flex;
  cursor: pointer;
  margin-top: 1.875rem;
  align-items: center;

  .step-number {
    height: 36px;
    width: 36px;
    min-width: 36px;
    border-radius: 50%;
    background-color: $c-yellow;
    font-size: 1.5rem;
    line-height: 2rem;
    color: $c-white;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .step-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.8125rem;
    color: $c-white;
  }

  .step-arrow {
    height: 48px;
    width: 48px;
    min-width: 48px;
    border-radius: 50%;
    background-color: $c-dark-blue;
    margin-left: auto;
    position: relative;

    &:after {
      content: "";
      height: 10px;
      width: 10px;
      border-left: 2px solid $c-white;
      border-bottom: 2px solid $c-white;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 0;
      top: 0;
      transform: rotate(-45deg);
    }
  }

  &.collapsed {
    .step-number {
      background-color: $c-dark-blue;
    }

    .step-arrow {
      &:after {
        transform: rotate(135deg);
      }
    }
  }
}

.stepper-tablet {
  .card-header {
    background-color: $c-blue;
    .step-item {
      padding: 1.6875rem 0;
      margin: 0;
    }
  }
  .card-body {
    padding-bottom: 3rem;
  }
}

.safety {
  @media (max-width: 482px) {
    .cotact-links {
      flex-direction: column;
      a {
        padding: 0 !important;
        padding-bottom: 1.25rem !important;
      }
    }
  }
}

.btn-group {
  a {
    &:first-child {
      border-radius: 50px 0px 0px 50px;

      button {
        border-radius: 50px 0px 0px 50px;
      }
    }

    &:last-child {
      border-radius: 0px 50px 50px 0px;

      button {
        border-radius: 0px 50px 50px 0px;
      }
    }
  }
}

.steps-content {
  .cg-card {
    margin: 1rem auto;
    opacity: 0.5;
    max-width: 12.25rem;
    &.active {
      opacity: 1;
    }
    .cg-card-banner {
      margin: 0 1rem;
      margin-bottom: -3.5rem;
    }
    .cg-card-footer {
      max-height: 30px;
    }
  }
}

.card-body {
  .cg-card {
    margin: 1rem auto;
    max-width: 12.25rem;
  }
  .cg-card-banner {
    margin: 0 1rem;
    margin-bottom: -3.5rem;
  }
  .cg-card-details {
    padding: 3.5rem 1rem 2rem;
  }
}

.intext-btn {
  min-width: unset !important;
  width: unset !important;
  max-height: 30px;
}
.tick-icon {
  font-size: 0.75rem;
}
.section-content {
  @media (max-width: 576px) {
    padding: 2rem 1rem 3rem;
  }
}
.no-wrap {
  white-space: nowrap;
}
.wrap {
  white-space: normal;
}
.accordion > .card {
  border-left: none;
  border-right: none;
}

.section-border-t-bold {
  border-top: 12px solid $c-white;
}

.section-border-t {
  border-top: 6px solid $c-white;
}

.section-border-b-bold {
  border-bottom: 12px solid $c-white;
}

.section-border-b {
  border-bottom: 6px solid $c-white;
}
