$c-blue: #0072ce;
$c-bright-blue: #57b1f9;
$c-light-blue : #eef7fe;
$c-navy: #001f4f;
$c-dark-blue: #002d72;
$c-dark-teal: #009484;
$c-teal: #00c7b1;
$c-light-teal: #e7f7f5;
$c-green: #78be20;
$c-dark-green: #5c9219;
$c-light-green: #f1f6ea;
$c-dark-purple: #4d1659;
$c-purple: #702082;
$c-light-purple: #f0eaf1;
$c-red: #e03c31;
$c-muted-red: #ed6e66;
$c-dark-orange: #da660d;
$c-orange: #f28028;
$c-light-orange: #f28028;
$c-yellow: #f4a800;
$c-dark-yellow: #da9600;
$c-dark-gray: #555555;
$c-med-gray: #929292;
$c-light-gray: #f5f5f5;

$c-white: #fff;
$header-hover: #e5eaf0;


$break-xs: 576px;
$break-sm: 768px;
$break-md: 992px;
$break-lg: 1200px;
