// Aside
.kids-account {
  .light-grey {
    background: #fafafa;
  }
  aside {
    padding: 1.75rem 2.375rem 1.75rem 2.0625rem;
    background-color: #0072ce;
    @media screen and (min-width: 992px) {
      width: 30%;
    }
    @media screen and (min-width: 1200px) {
      width: 35%;
    }
    h4 {
      font-family: Bangers;
      font-style: normal;
      font-weight: normal;
      font-size: 4rem;
      line-height: 4.25rem;
      color: #ffffff;
      margin-bottom: 1rem;
    }
    p {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 1.25rem;
      line-height: 1.875rem;
      color: #ffffff;
    }
  }
  main {
    @media screen and (min-width: 992px) {
      width: 70%;
      border-left: 6px;
    }
    @media screen and (min-width: 1200px) {
      width: 65%;
    }
    .top-border {
      border-top: 12px solid #fff;
    }
    .content-space {
      padding: 2.5rem 0rem 2.5rem 0rem;
      @media screen and (min-width: 992px) {
        padding: 1.8rem 1rem 1.8rem 1rem;
      }
      @media screen and (min-width: 1200px) {
        padding: 2rem 3rem 2rem 1.25rem;
      }
    }

    .user-info {
      &-img {
        width: 6.25rem;
        height: 6.25rem;
        border-radius: 50%;
        position: relative;
        background-color: #ed7601;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 6.25rem;
          border-bottom-left-radius: 50%;
          border-bottom-right-radius: 50%;
        }
      }
      &-text {
        margin-left: 1rem;
        @media screen and (min-width: 992px) {
          margin-left: 1.75rem;
        }
        h4 {
          font-weight: 600;
          font-size: 1.75rem;
          line-height: 2.375rem;
          color: #002d72;
        }
        p {
          font-weight: normal;
          font-size: 1.25rem;
          line-height: 1.875rem;
          color: #002d72;
        }
      }
    }
    // BG
    .bg-title {
      h4 {
        font-weight: 600;
        font-size: 1.75rem;
        line-height: 2.375rem;
        color: #002d72;
      }
    }
    .avatar-title {
      @extend .bg-title;
    }
    // Load More Btn
    button.load-more-btn {
      margin-top: 3.125rem;
      background-color: #002d72;
      margin-bottom: 0;
    }
  }
}

// Start BG color Set
.bg-colors {
  &-color {
    margin: 0.5rem 0.5rem;
    label {
      width: 1.8rem;
      height: 1.8rem;
      &:last-child {
        margin-right: 0;
      }
      @media screen and (min-width: 992px) {
        width: 2.5rem;
        height: 2.5rem;
      }
      border-radius: 50%;
      position: relative;
      margin: auto;
      &:hover {
        cursor: pointer;
      }
      img {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 85%;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
    input {
      opacity: 0;
      z-index: -1;
      display: none;
      &:after {
        display: none;
      }
      &:checked + label {
        &:before {
          content: "";
          position: absolute;
          left: -5px;
          right: 0;
          bottom: 0;
          top: 0;
          margin: auto;
          width: 2.4rem;
          height: 2.4rem;
          border-radius: 50%;
          background-color: transparent;
          border: 3px #0072ce solid;
          @media screen and (min-width: 992px) {
            width: 3.625rem;
            height: 3.625rem;
            border: 5px #0072ce solid;
            left: -9px;
          }
        }
      }
    }
  }
}

// Start Icons Set
.icons-set {
  &-item {
    width: 6.25rem;
    margin-top: 2rem;
    display: flex;
    align-items: flex-end;

    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    flex: 0 1 calc(33.33333333%);
    @media screen and (min-width: 768px) {
      flex: 0 1 calc(20%);
    }
    @media screen and (min-width: 1200px) {
      flex: 0 1 calc(16.666667%);
      &:nth-child(6n) {
        justify-content: flex-end;
      }
      &:not(:first-child):not(:nth-child(6n)):not(:nth-child(6n + 7)) {
        justify-content: center;
      }
    }
    label {
      position: relative;
      & > span {
        width: 5.3125rem;
        height: 5.3125rem;
        display: flex;
        border-radius: 50%;
        position: relative;
        align-items: center;
        justify-content: center;
        @media screen and (min-width: 768px) {
          width: 6.25rem;
          height: 6.25rem;
        }

        &:hover {
          cursor: pointer;
        }
        img {
          //max-height: 85%;
          border-bottom-left-radius: 50%;
          border-bottom-right-radius: 50%;
        }
      }
    }

    /*&.kids label > span {
      overflow: hidden;
      align-items: flex-end;
    }*/

    input {
      opacity: 0;
      z-index: -1;
      display: none;
      &:after {
        display: none;
      }
      &:checked + label {
        &:before {
          content: "";
          position: absolute;
          left: -10px;
          right: 0;
          bottom: 0;
          top: 0;
          margin: auto;
          border-radius: 50%;
          background-color: transparent;
          border: 5px #0072ce solid;
          width: 6.5625rem;
          height: 6.5625rem;
          @media screen and (min-width: 768px) {
            width: 7.5rem;
            height: 7.5rem;
          }
        }
      }
    }
  }
}

// Start Kids Profile Information
.kids-profile-information {
  h4 {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2.375rem;
    color: #0072ce;
  }
  label {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.875rem;
    color: #002d72;
  }
}

// Aditional info
.account-additional-info {
  font-family: Nunito;
  .light-grey {
    background: #fafafa;
  }
  h4 {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2.375rem;
    color: #0072ce;
    padding-top: 6.75rem;
  }
  p {
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: #002d72;
    padding-bottom: 6.125rem;
  }
}

// Parent Profile Account
.parent-profile-account {
  aside,
  main {
    font-family: Nunito;
    background: #fafafa;
    h4 {
      font-weight: 600;
      font-size: 1.75rem;
      line-height: 2.375rem;
      color: #0072ce;
      margin-bottom: 2rem;
    }
    p,
    a {
      font-size: 1rem;
      line-height: 1.875rem;
      color: #002d72;
    }
    p {
      font-weight: normal;
      &.change-password {
        line-height: 1.5rem;
        font-style: italic;
      }
    }
    a {
      font-weight: 600;
    }
    label.delivery-form {
      margin-bottom: 0.5rem;
      color: navy;
      opacity: 0.6;
      &.change-password {
        opacity: 1;
        font-weight: 600;
      }
    }
    input.form-control {
      margin-bottom: 1.25rem;
      font-size: 1rem;
      line-height: 22px;
      &::-webkit-input-placeholder {
        color: #002d72;
      }
      &::-ms-input-placeholder {
        color: #002d72;
      }
      &::placeholder {
        color: #002d72;
      }
      &.change-password {
        margin-bottom: 0rem;
      }
    }
  }
  main {
    padding: 3.625rem 1.5625rem 3.625rem 1.5625rem;
    @media screen and (min-width: 768px) {
      padding: 6.75rem calc(4.5625rem - 15px) 6.5625rem calc(4.5625rem - 15px);
    }
    @media screen and (min-width: 992px) {
      padding: 6.75rem calc(8rem - 15px) 6.5625rem calc(4.5625rem - 15px);
    }
    .horizontal-line {
      width: 100%;
      height: 2px;
      background-color: rgba(#979797, 0.2);
      margin: 3.375rem 0;
    }
  }
  aside {
    padding: 3.625rem 1.5625rem 3.625rem 1.5625rem;
    max-width: 100%;
    @media screen and (min-width: 768px) {
      padding: 6.75rem calc(2.625rem) 6.5625rem calc(2.625rem);
    }
    @media screen and (min-width: 992px) {
      padding: 6.75rem calc(2.625rem) 6.5625rem calc(3.375rem);
    }
    h4 {
      margin-bottom: 0.875rem;
    }
    p {
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
    }
    .info-text {
      @media screen and (min-width: 768px) {
        width: 45%;
      }
      @media screen and (min-width: 1200px) {
        width: 100%;
      }
    }
    .card {
      border: 0;
      border-radius: 0;
      padding: 1.5625rem 1rem 2rem 1rem;
      @media screen and (min-width: 768px) {
        width: 50%;
        padding: 1.5625rem 3rem 2rem 1.4375rem;
        margin-left: 5%;
      }
      @media screen and (min-width: 1200px) {
        width: 100%;
        margin-left: 0;
      }

      ul {
        li {
          i {
            color: #57b1f9;
            padding-right: 0.625rem;
          }
          p,
          a {
            color: #002d72;
          }
          p {
            font-size: 1.3125rem;
            font-weight: 600;
          }
          a {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
