.comment {
  display: flex;
  justify-content: flex-start;
  padding: 0.75rem 0;
  flex-direction: column;
  p {
    margin-bottom: 1rem;
  }
  @media screen and (min-width: $break-sm) {
    flex-direction: row;
  }
  &-detail {
    flex: 1;
    max-width: 80%;
    @media screen and (min-width: $break-sm) {
      max-width: calc(100% - 4.875rem);
    }
    &-subtitle {
      font-weight: 600;
      font-family: Nunito;
      color: black;
    }
    &-title{
      max-width: 80%;
      @media screen and (min-width: $break-sm) {
        max-width: calc(100% - 101px  - 5.875rem);
      }
    }
  }
  &-avatar {
    /*flex: 0 0 20%;*/
    max-width: 20%;
    margin-right: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: $break-sm) {
      width: 4.875rem;
      height: 4.875rem;
      max-width: 100%;
      align-self: flex-start;
      .leave-commment-box-inner & {
        flex: 1;
      }
    }

    & > img {
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
    }
  }
  &-date {
    font-size: 0.8125rem;
    line-height: 1.125rem;
    color: $c-dark-blue;
  }
  &-author {
    font-weight: 600;
    margin-bottom: initial;
    overflow: hidden;
  }
  .lnk-comment-reply {
    color: $c-bright-blue;
    i {
      margin-right: 5px;
      font-size: 0.75rem;
    }
  }
  &-container {
    border-bottom: 1px solid rgba(151, 151, 151, 0.23);
    padding: 1rem 0;
    &:last-of-type {
      border-bottom: none;
    }
  }
}

.reply {
  margin-left: calc(10% + 1rem);
  position: relative;
}

.lnk-comments-all {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.625rem;
  color: $c-blue;
  margin: 1rem 0;
  display: block;
}

.leave-commment-box {
  margin-top: 5rem;
  position: relative;
  background: $c-white;
  padding: 2.75rem 1.875rem;
  border-top: 10px solid $c-bright-blue;
  &-inner {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    @media screen and (min-width: $break-sm) {
      flex-direction: row;
    }
  }
  .login-hint {
    display: none;
    font-family: GamjaFlower;
    font-size: 1.75rem;
    line-height: 2.25rem;
    color: $c-dark-blue;
    &.parent-mode-review {
      display: block;
    }
  }
  &-orange {
    border-top: 10px solid $c-orange;
    h3,
    .login-hint {
      color: $c-orange;
    }
  }

  &.disabled {
    .comment-avatar,
    h3 {
      opacity: 0.23;
    }
    .login-hint {
      display: block;
      flex: 1;
      &.parent-mode-review {
        display: none;
      }
    }
    .rating-stars{
      opacity: 0.23;
    }
  }
  &-sticker {
    position: absolute;
    top: -8.5rem;
    left: -2rem;
    @media screen and (min-width: $break-sm) {
      top: -9.5rem;
      left: -3rem;
    }
    .stiker-block {
      position: relative;
      top: 0;
      left: 0;
      width: 10.5rem;
      height: 8.125rem;
      transform: none;
      @media screen and (min-width: $break-sm) {
        width: 13.1875rem;
        height: 10.1875rem;
      }
      .stiker-text {
        font-size: 1.75rem;
        transform: rotate(-21deg);
        padding: 0 1rem;
        line-height: 1;
        @media screen and (min-width: $break-sm) {
          font-size: 2.375rem;
        }
      }
    }
  }
}

.leave-commment-input {
  width: 100%;
  input[type="submit"] {
    margin-top: 1rem;
  }
}

.video-review-thumbnail {
  display: block;
  position: relative;
  &:before {
    position: absolute;
    bottom: 0;
    color: #fff;
    font-size: 3.125rem;
    padding: 1rem;
    line-height: 1;
    cursor: pointer;
    @media screen and (min-width: $break-sm) {
      font-size: 5.125rem;
    }
    @media screen and (min-width: $break-md) {
      font-size: 5.5rem;
    }
  }
}

div#reply-comment {
  margin-bottom: 1rem;
  margin-top: 1rem;
  textarea.form-control {
    min-height: 4rem;
  }
  h3 {
    font-size: 1.3125rem;
  }
}

.approval-note {
  color: $c-yellow;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  .icon {
    color: $c-white;
    font-size: 1rem;
    color: $c-yellow;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    margin-right: 0.25rem;
  }
}

.reply-sucess {
  &.alert.alert-dismissible {
    padding-right: 2rem;
    background: $c-blue;
    color: $c-white;
    border: none;
  }
  .close {
    color: $c-white;
    font-size: 0.5rem;
    opacity: 1;
    padding: 0.25rem;
    border-radius: 50%;
    border: 1px solid;
    top: calc(50% - 0.625rem);
    right: 0.75rem;
  }
}

#leave-review-box {
  .leave-commment-box {
    .login-hint {
      flex: 1;
    }
  }
}