@font-face {
	font-family: "pjow-icons";
	src: url('../fonts/pjow-icons.eot');
	src: url('../fonts/pjow-icons.eot?#iefix') format('eot'),
		url('../fonts/pjow-icons.woff2') format('woff2'),
		url('../fonts/pjow-icons.woff') format('woff'),
		url('../fonts/pjow-icons.ttf') format('truetype'),
		url('../fonts/pjow-icons.svg#pjow-icons') format('svg');
}

.icon:before {
	font-family: "pjow-icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-alert-book-guide:before {
	content: "\E001";
}

.icon-alert-o-book-guide:before {
	content: "\E002";
}

.icon-approved:before {
	content: "\E003";
}

.icon-archive:before {
	content: "\E004";
}

.icon-author:before {
	content: "\E005";
}

.icon-award-book-guide:before {
	content: "\E006";
}

.icon-award:before {
	content: "\E007";
}

.icon-badge:before {
	content: "\E008";
}

.icon-blogs:before {
	content: "\E009";
}

.icon-book-header:before {
	content: "\E00A";
}

.icon-book-opened:before {
	content: "\E00B";
}

.icon-book:before {
	content: "\E00C";
}

.icon-books:before {
	content: "\E00D";
}

.icon-building:before {
	content: "\E00E";
}

.icon-calendar-order:before {
	content: "\E00F";
}

.icon-calendar:before {
	content: "\E010";
}

.icon-camera:before {
	content: "\E011";
}

.icon-caret-right:before {
	content: "\E012";
}

.icon-check-circle:before {
	content: "\E013";
}

.icon-check:before {
	content: "\E014";
}

.icon-chevron-left:before {
	content: "\E015";
}

.icon-chevron-right:before {
	content: "\E016";
}

.icon-close:before {
	content: "\E017";
}

.icon-comments:before {
	content: "\E018";
}

.icon-completed:before {
	content: "\E019";
}

.icon-dots-circle:before {
	content: "\E01A";
}

.icon-dots:before {
	content: "\E01B";
}

.icon-email:before {
	content: "\E01C";
}

.icon-grid:before {
	content: "\E01D";
}

.icon-help:before {
	content: "\E01E";
}

.icon-info-book-guide:before {
	content: "\E01F";
}

.icon-link:before {
	content: "\E020";
}

.icon-list-items:before {
	content: "\E021";
}

.icon-location:before {
	content: "\E022";
}

.icon-lock:before {
	content: "\E023";
}

.icon-loop:before {
	content: "\E024";
}

.icon-magnifying-glass:before {
	content: "\E025";
}

.icon-mail:before {
	content: "\E026";
}

.icon-message-book-guide:before {
	content: "\E027";
}

.icon-minus:before {
	content: "\E028";
}

.icon-not-approved:before {
	content: "\E029";
}

.icon-np-list:before {
	content: "\E02A";
}

.icon-o-star:before {
	content: "\E02B";
}

.icon-on-the-way:before {
	content: "\E02C";
}

.icon-pages:before {
	content: "\E02D";
}

.icon-pending:before {
	content: "\E02E";
}

.icon-percent:before {
	content: "\E02F";
}

.icon-phone:before {
	content: "\E030";
}

.icon-play-triangle:before {
	content: "\E031";
}

.icon-plus-book-guide:before {
	content: "\E032";
}

.icon-plus:before {
	content: "\E033";
}

.icon-purse-book-guide:before {
	content: "\E034";
}

.icon-question:before {
	content: "\E035";
}

.icon-repeat:before {
	content: "\E036";
}

.icon-snazzy:before {
	content: "\E037";
}

.icon-snowflake:before {
	content: "\E038";
}

.icon-star-book-guide:before {
	content: "\E039";
}

.icon-star-half:before {
	content: "\E03A";
}

.icon-star-o-book-guide:before {
	content: "\E03B";
}

.icon-star-quarter-less:before {
	content: "\E03C";
}

.icon-star-quarter-more:before {
	content: "\E03D";
}

.icon-star:before {
	content: "\E03E";
}

.icon-tag:before {
	content: "\E03F";
}

.icon-text-file:before {
	content: "\E040";
}

.icon-text:before {
	content: "\E041";
}

.icon-thumbnail-book-guide:before {
	content: "\E042";
}

.icon-thumbnail-o-book-guide:before {
	content: "\E043";
}

.icon-times-circle:before {
	content: "\E044";
}

.icon-times:before {
	content: "\E045";
}

.icon-user:before {
	content: "\E046";
}

.icon-video:before {
	content: "\E047";
}

.icon-way-to-go:before {
	content: "\E048";
}
