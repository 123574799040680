.modal-dialog .modal-body {
  padding: 1.5rem 0.5rem;
}
.btn-forgot-username {
  &:hover {
    color: $header-hover;
    cursor: pointer;
  }
}
.btn-forgot-password {
  &:hover {
    color: $header-hover;
    cursor: pointer;
  }
}

.with-border {
  border: 6px solid white;
}

.modal {
  background-color: rgba(21, 36, 134, 0.57);
}

.modal-dialog {
  max-width: 878px;
}

.modal-content {
  background-color: transparent;
  border: 0;
}

.parent {
  .modal-pj-dialog-main {
    .modal-pj-header {
      font-family: BebasNeue;
      h4 {
        font-family: BebasNeue;
      }
    }
    .modal-pj-sections.section-sm-md .by-this-autor {
      background-color: #002d72;
      div {
        font-family: BebasNeue;
      }
    }
  }
}

.modal-pj-dialog-main {
  font-family: Nunito;
  .modal-pj-dialog {
    position: relative;
    .close-modal {
      font-family: Nunito;
      font-size: 1.25rem;
      font-weight: 600;
      position: absolute;
      top: -40px;
      right: 0;
      color: white;
      cursor: pointer;
      text-transform: uppercase;
      i {
        font-size: 0.875rem;
      }
      &:hover {
        color: $header-hover;
      }
    }
    .btn-forgot-username,
    .btn-forgot-password {
      &:hover {
        color: $header-hover;
        cursor: pointer;
      }
    }
  }
  .modal-pj-header {
    font-family: Bangers;
    font-size: 3rem;
    line-height: normal;
    padding: 1rem;
    &.section-header-teal {
      background-color: $c-teal;
    }
    h4 {
      font-family: Bangers;
      font-size: 2rem;
      color: white;
      margin-left: 0.375rem;
      line-height: normal;
    }
    @media screen and (min-width: 768px) {
      h4 {
        font-size: 3rem;
      }
      padding: 2rem;
    }
    text-align: left;
    color: white;
    background-color: $c-blue;
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: center;
      h4 {
        text-align: center;
      }
    }
    .parent & {
      font-family: BebasNeue;
      background-color: $c-dark-blue;
      font-weight: 600;
    }
  }
  .modal-pj-horizontal-section {
    background-color: $c-light-blue;
    padding: 1rem 2rem;
    &.bg-light {
      background-color: $c-light-green;
      padding-bottom: 30px !important;
      .c-btn {
        margin: 2.75rem 0 0 1.25rem;
      }
    }

    .modal-pj-section-text {
      font-size: 1rem;
      color: $c-dark-blue;
      h2 {
        font-size: 1.25rem;
        font-family: Nunito;
        color: #002d72;
        line-height: 1.5;
      }
    }
  }
  .modal-pj-sections {
    .by-this-autor {
      background-color: $c-bright-blue !important;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Bangers;
      font-size: 2.75rem;
      color: white;
      height: 100%;
    }
    .no-padding {
      padding: 0 !important;
    }
    .text-in-section {
      font-size: 1.25rem;
      width: auto;
      padding: 15px 35px;
      padding-bottom: 0px;
      color: #fff;
      background-color: #57b1f9;
      @media screen and (max-width: 767px) {
        text-align: center;
        font-size: 1rem;
      }
    }
    .with-border-bottom {
      padding-bottom: 20px;
      border-bottom: 12px solid white;
    }
    .section-with-padding {
      padding: 30px;
    }
    .book-raiting-summary {
      .header-raiting-summary {
        font-size: 48px;
        color: $c-dark-blue;
        line-height: 1.2;
        padding-bottom: 40px;
      }
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        .header-raiting-summary {
          font-size: 1.3125rem;
        }
      }
    }
    .input-pj-group {
      width: 100%;
      padding: 0.5rem 0;
    }
    .input-checkbox-pj-group {
      padding-top: 0.75rem;
    }
    .blue-text {
      color: $c-dark-blue;
      padding: 1rem 0 2rem;
      font-size: 1rem;
    }
    .btn-submit,
    .login-btn {
      margin: 0.5rem 0;
      margin-top: 1rem;
      display: block;
    }
    .modal-pj-section {
      background-color: $c-light-blue;
      padding: 2rem;
      &.section-content-light-teal {
        background-color: $c-light-teal;
      }
      &.with-img img {
        width: 100%;
      }
      &.one-section {
        width: 100%;
        text-align: center;
        padding: 25px 150px;
        .badge-icon {
          width: 11rem;
          margin: 0 auto;
          margin-top: 10px;
          img {
            width: 100%;
          }
        }
      }
      .about-autor-text {
        color: #555555;
        p {
          padding-bottom: 20px;
          color: #555555;
          font-size: 1rem;
        }
      }
      .modal-pj-section-text {
        color: $c-dark-blue;
        font-size: 18px;
        h2 {
          font-weight: bold;
          font-size: 28px;
          font-family: Nunito;
          color: #002d72;
          line-height: 1.5;
        }
      }
      .error-message-pj {
        display: none;
        background-image: linear-gradient(98deg, #ea685f, #e03c31);
        color: white;
        position: relative;
        padding: 15px;
        padding-left: 37px;
        border-radius: 5px;
        &.show {
          display: block;
        }
        .close {
          font-size: 0.5rem;
          left: 0.5rem;
          opacity: 1;
          top: 1rem;
          padding: 0.25rem;
          border-radius: 50%;
          border: 1px solid;
          width: 18px;
          height: 19px;
          &:hover {
            color: rgb(228, 228, 228);
          }
        }
      }
      &.signup-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        text-align: center;
        font-family: Nunito;
        background-image: url(../img/burst-bg-small.svg);
        background-color: $c-purple;
        background-size: cover;
        background-repeat: no-repeat;
        .parent & {
          background-color: $c-blue;
        }
        .text-headder {
          font-size: 1.375rem;
          padding-bottom: 0.5rem;
        }
        .text-section {
          font-size: 1rem;
          line-height: 1.625rem;
        }
        .section-btns {
          padding-top: 1rem;
          .btn-sign-up {
            display: none;
            .parent & {
              display: inline-block;
            }
          }
          .hint-text {
            font-family: "GamjaFlower";
            padding-top: 1rem;
            font-size: 1.75rem;
            line-height: 2.25rem;
            .parent & {
              display: none;
            }
          }
          & > .c-btn {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .preview-iframe {
    min-height: 700px;
    overflow: hidden;
  }
  .forget-section {
    // background-color: $c-teal;
    background-color: #002d72;
    width: 100%;
    text-align: center;
    color: white;
    padding: 1.625rem 1rem 1.625rem 1rem;
    font-size: 1rem;
    .parent & {
      background-color: $c-blue;
      //background-color: #002d72;
    }
    div {
      position: relative;
      .with-dot {
        font-size: 10px;
        position: absolute;
        top: 5px;
      }
    }
  }
}

.error-message-pj {
  display: none;
  background-image: linear-gradient(98deg, #ea685f, #e03c31);
  color: white;
  position: relative;
  padding: 0.6rem 1.25rem;
  padding-left: 37px;
  border-radius: 5px;
  &.show {
    display: block;
  }
  .close {
    font-size: 0.5rem;
    left: 0.5rem;
    opacity: 1;
    top: 0.8rem;
    padding: 0.25rem;
    border-radius: 50%;
    border: 1px solid;
    width: 18px;
    height: 19px;
    &:hover {
      color: rgb(228, 228, 228);
    }
  }
}

.error-password-reset {
  display: none;
  background-image: linear-gradient(98deg, #ea685f, #e03c31);
  color: white;
  position: relative;
  padding: 0.6rem 1.25rem;
  border-radius: 5px;
  &.show {
    display: block;
  }
}

.section-in-sign-up {
  .icon-check {
    color: white;
    background-color: #78be20;
    padding: 3px 11px;
    border-radius: 50%;
    width: 51px;
    font-size: 30px;
    float: left;
  }
}

.modal-pj-header {
  .icon-question,
  .icon-check {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #f4a800;
    border-radius: 50%;
    font-size: 30px;
    width: 48px;
    height: 48px;
    position: relative;
    bottom: 6px;
  }
  .icon-check {
    background-color: $c-green;
    padding: 11px 7px 11px 11px;
  }
}

// Author Info
.modal-autor-info {
  .modal-dialog {
    max-width: 1300px;
    @media screen and (max-width: 767px) {
      .modal-pj-section {
        padding: 1rem 1rem;
      }
    }
    .author-img {
      @media screen and (min-width: 768px) {
        height: 100%;
        padding: 0;
      }
    }
    .author-text {
      height: 100%;
    }
    #carouselAutorInfo {
      margin: 0 0 3rem;
      .slick-arrow {
        i {
          line-height: 24px;
          display: flex;
        }
      }
      @media screen and (min-width: 992px) {
        margin: 0 3rem 2rem;
      }
      .slick-track {
        display: flex !important;
        .slick-slide {
          height: auto;
        }
      }
      .slick-prev {
        left: 0;
        bottom: -5rem;
        @media screen and (min-width: 992px) {
          left: -4rem;
          top: 10rem;
        }
      }
      .slick-next {
        right: 0;
        bottom: -5rem;
        @media screen and (min-width: 992px) {
          right: -4rem;
          top: 10rem;
        }
      }
      .slick-dots {
        bottom: -3rem;
      }
      .cl-card {
        height: 100%;
      }
      .cl-card .cont {
        padding: 0.625rem;
      }
      .cl-card-img {
        img {
          height: 100%;
        }
      }
    }
  }
}

.section-in-sign-up {
  padding: 40px 0;
  .text-section {
    width: 270px;
    padding: 0 25px;
    float: left;
  }
  button {
    margin-top: 5px;
  }
}

.button-group {
  button {
    margin: 5px;
  }
}

@media (max-width: 870px) {
  .modal-sign-up2 {
    .modal-pj-header {
      line-height: 1;
    }
  }
  .close-modal {
    top: -35px !important;
  }
  .modal-pj-section.one-section {
    padding: 25px !important;
  }
}

@media (max-width: 700px) {
  .section-in-sign-up {
    text-align: center;
    .icon-check {
      float: none;
      margin: 0 auto;
    }
    .text-section {
      width: 100%;
      padding: 10px 0;
    }
    button {
      margin-top: 5px;
    }
  }
}

.modal-video .modal-pj-dialog {
  background-color: #000;
}
