.form-pj-section {
  margin-top: 135px;
  .form-pj-section-cont {
    height: 100%;
    border-radius: 11px;
    border: 12px solid whitesmoke;
    box-shadow: 0px 0px 16px -5px #002d72;
  }

  .input-pj-group,
  .textarea-pj-group {
    padding: 10px;
    min-width: 320px;
    float: left;
  }
}

@media (max-width: 490px) {
  .input-pj-group,
  .textarea-pj-group {
    min-width: auto;
    width: 100%;
    input,
    textarea,
    select {
      width: 100%;
    }
  }
}
