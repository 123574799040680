.disabled {
  label {
    color: rgba(0, 45, 114, 0.47);
  }
}

.input-pj-group label {
  color: #001f4f;
  font-weight: 600;
  line-height: 1;
  font-size: 1rem;
}

input[type="text"].form-control,
input[type="email"].form-control,
input[type="password"].form-control,
input[type="date"].form-control,
textarea.form-control,
select.form-control {
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  font-size: 1rem;
  padding: 1rem;
  width: 100%;
  height: auto;
  line-height: 1.375rem;
  color: #929292;
  &::placeholder {
    color: $c-med-gray;
  }
  &:focus {
    box-shadow: 0px 0px 8px -1px #57b1f9 inset;
    border: 1px solid #7fc6ff;
    outline: 0;
  }
  &:focus:invalid,
  &.input-validation-error {
    box-shadow: 0px 0px 8px -1px #ed6e66 inset;
    border: 1px solid #ed6e66;
    //border-bottom-right-radius: 0px;
    //border-bottom-left-radius: 0px;
    & + .error-pj-input {
      display: block;
    }
  }
  .disabled & {
    color: #cacaca;
    pointer-events: none;
    border-color: $c-light-gray;
    &::placeholder {
      color: rgb(218, 218, 218);
    }
  }
  &.review-title {
    margin-bottom: 1rem;
    &.input-validation-error {
      box-shadow: 0px 0px 8px -1px #ed6e66 inset;
      border: 1px solid #ed6e66;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      margin-bottom: 0rem;
      & + .error-pj-input {
        display: block;
        margin-bottom: 1rem;
      }
    }
  }
}

select.form-control {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, #f4a800, #f4a800);
  background-position: calc(100% - 27px) calc(1em + 5px), calc(100% - 17px) calc(1em + 5px), 100% 0;
  background-size: 10px 10px, 10px 10px, 56px 56px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.field-validation-error {
  background-image: linear-gradient(97deg, #e03c31, #e03c31);
  margin-top: -3px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  color: white;
  padding: 0.375rem 0.75rem;
  font-weight: 600;
  width: 100%;
}

input[type="checkbox"].form-control {
  -webkit-appearance: none;
  background-color: #fff;
  padding: 0.5rem;
  display: inline-block;
  position: relative;
  border: 1px solid #c7c7c7;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  max-width: 1.75rem;
  max-height: 1.75rem;
  &:checked:after {
    left: 8px;
    top: 0;
    width: 11px;
    height: 20px;
    border: solid $c-dark-yellow;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: " ";
    display: block;
    position: absolute;
  }
  &:focus {
    box-shadow: 0px 0px 8px -1px #57b1f9 inset;
    border: 1px solid #7fc6ff;
    outline: 0;
  }
}

.input-checkbox-pj-group {
  display: flex;
  align-items: center;
  &.disabled {
    label {
      color: rgba(0, 45, 114, 0.47);
      pointer-events: none;
    }
  }
  label {
    cursor: pointer;
    font-size: 1rem;
    padding-left: 10px;
    line-height: 1.375rem;
    color: #929292;
    margin-bottom: 0;
  }
  input[type="radio"] {
    display: none;
    & + span {
      display: inline-block;
      background-color: transparent;
      border: 1px solid #c7c7c79c;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      margin: -1px 10px 0 0;
      vertical-align: middle;
      cursor: pointer;
    }

    &:checked + span::before {
        content: "";
        display: inline-block;
        background: $c-dark-yellow;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        margin: 0.185rem 0.185rem;
    }
  }
}

.textarea-pj-group {
  .error-pj-input {
    margin-top: -7px;
  }
}

textarea.form-control {
  min-height: 7.5rem;
  resize: none;
}

.ktc-file-component-wrapper .form-control {
  background-color: #eef7fe;
  border-color: #eef7fe;
  border-bottom: 1px solid #ced4da;
  border-radius: 0px;
  width: 100%;
  padding-bottom: 3rem;
  color: #929292;
}

.ktc-file-component-wrapper .form-control::-webkit-file-upload-button {
  visibility: hidden;
}

.ktc-file-component-wrapper .form-control::before {
  content: "Upload File";
  font-size: 1.125rem;
  line-height: 1;
  font-family: "BebasNeue";
  text-align: center;
  margin-right: 7px;
  background-color: #0072ce;
  color: white;
  text-transform: capitalize;
  border-radius: 50px;
  border: none;
  outline: none;
  transition-duration: 0.4s;
  display: inline-block;
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.ktc-file-component-wrapper .form-control:hover::before {
  background-color: #002d72;
  transform: scale(1.1) !important;
}

.ktc-file-component-wrapper a {
  font-weight: bold;
  color: #002d72;
  font-size: 1rem;
}

.ktc-file-component-wrapper .form-control:focus {
  box-shadow: 0px 0px 8px -1px #eef7fe;
}

.reset-password-form {
  .input-pj-group {
    padding: 0.5rem;
  }
}

.reset-password {
  color: $c-navy;
  font-size: 1.25rem;
  line-height: 1.875rem;
  padding: 2rem 0 2rem 1rem;
  min-height: 30rem;
  @media screen and (min-width: $break-md) {
    padding: 5rem 4.25rem;
  }
}

.error-password-reset {
  display: none;
  background-image: linear-gradient(98deg, #ea685f, #e03c31);
  color: white;
  position: relative;
  border-radius: 5px;
  text-align: center;
  margin-top: 5rem;
  &.show {
    display: block;
  }
}

.success-password-reset {
  padding-right: 2rem;
  background: $c-blue;
  color: $c-white;
  border: none;
  text-align: center;
  margin-top: 5rem;
  &.show {
    display: block;
  }
}
