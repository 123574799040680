@mixin profileInfoSection($descBg, $progressBg, $progressTitle, $progressCount, $progressText) {
    h4 {
        font-family: Bangers;
        font-style: normal;
        font-weight: normal;
        font-size: 2.75rem;
        line-height: normal;
        margin-bottom: 1.5625rem;
        @media screen and (min-width: 992px) {
            font-size: 4rem;
        }
    }
    .bookshelf-desc {
        background-color: $descBg;
        padding: 2.5125rem 2.8125rem;
        height: 100%;
        h4 {
            text-align: center;
            color: #ffffff;
        }
        p {
            font-family: Nunito;
            font-size: 1.25rem;
            line-height: 1.875rem;
            color: #fff;
        }
        @media screen and (min-width: 992px) {
            h4 {
                text-align: left;
            }
            border-right: 6px solid #ffffff;
        }
    }
    .bookshelf-progress {
        // padding: 2rem 1.5625rem;
        padding: 2rem 0.5rem; // Here edit
        background: $progressBg;
        height: 100%;
        h4 {
            color: $progressTitle;
        }
        .progress-group-bar {
            width: 100%;
        }
        .progress {
            width: 100%;
            height: 40px;
            border-radius: 20.5px;
            background-color: #fff;
            &-bar {
                background-color: #f4a800;
            }
        }
        @media screen and (min-width: 992px) {
            border-left: 6px solid #ffffff;
            padding: 2.5125rem 2rem;
            .progress {
                width: 450px;
            }
            .progress-group-bar {
                width: auto;
            }
        }
    }
    .count-group {
        &-item {
            @media screen and (max-width: 767px) {
                flex: 0 50%;
            }
            span {
                font-family: BebasNeue;
                font-size: 3.8125rem;
                text-align: center;
                color: $progressCount;
                @media screen and (min-width: 768px) {
                    font-size: 5.0625rem;
                }
            }
            img {
                padding-left: 0.9rem;
            }
            .icon {
                font-size: 3.5rem;
                line-height: 1;
                color:$progressCount;
                //height: 56px;
                padding-left: 0.9rem;
                &:before{
                    display: block;
                }
            }
            p {
                font-family: Nunito;
                font-style: normal;
                font-weight: 600;
                font-size: 1.25rem;
                color: $progressText;
            }
            @media screen and (min-width: 992px) {
                &:not(:last-child) {
                    margin-right: 4rem;
                }
                &:nth-child(4n) {
                    margin-right: 0;
                }
                width: calc(25% - 4rem);
            }
        }
    }
}