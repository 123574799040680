.poll-widget {
  /*box-shadow: 0px 2px 12px rgba(0, 45, 114, 0.4);
  background-color: $c-white;
  border-radius: 1rem;*/
  &-title {
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-family: Nunito;
    color: $c-white;
    padding: 1rem 1.5rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background: $c-purple;
    min-height: 7.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-answer {
    padding: 4.125rem 1.875rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;

    &-with-bg {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    &-mask {
      height: 1.125rem;
      border-radius: 0.5rem;
      background-color: rgba(216, 216, 216, 0.5);
      margin: 0.75rem 0 1.25rem 2rem;
    }
    &-item {
      position: relative;
      &.selected {
        margin-top: 2rem;
      }
    }
    &-results {
      height: 100%;
      background: $c-purple;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      position: relative;
      .result {
        font-family: BebasNeue;
        font-size: 1.125rem;
        width: 2.75rem;
        height: 2.75rem;
        background: $c-dark-purple;
        color: $c-white;
        border-radius: 50%;
        padding: 0.5rem;
        position: absolute;
        top: -0.75rem;
        right: -1.375rem;
        box-shadow: 0px 2px 4px rgba(0, 45, 114, 0.4);
      }
    }
    .input-checkbox-pj-group {
      width: auto;
      display: flex;
      margin-bottom: 1rem;
      & > label {
        font-size: 0.875rem;
        font-family: Nunito;
        font-weight: 600;
        padding: 0;
        color: $c-navy;
        & > input[type="radio"] {
          & + span {
            width: 1.375rem;
            height: 1.375rem;
            border: 1px solid $c-purple;
            margin: 0 0.75rem 0 0;
            background: $c-white;
            display: inline-block;
            border-radius: 20px;
            vertical-align: middle;
            cursor: pointer;
          }

          &:checked + span:before {
            content: "";
            display: inline-block;
            background: $c-yellow;
            width: 0.875rem;
            height: 0.875rem;
            border-radius: 50%;
            margin: 0.185rem 0.185rem;
          }
        }
      }
    }

    .c-btn {
      margin: 0.75rem 0;
    }
  }
}

.poll-answered {
  background: #f4a800 !important;
}

.undo-text {
  border: none;
  background: none;
  font-size: small;
}

.poll-results {
  display: flex;
  flex-direction: column;
  height: 100%;
  .poll-widget-title {
    font-family: Bangers;
    font-size: 3.25rem;
    line-height: 3.5rem 1.5rem;
    padding: 2.5rem;
  }
  .input-checkbox-pj-group input[type="radio"] + label span {
    display: none;
  }

  .poll-widget-answer-mask {
    margin-left: 0;
  }

  .poll-answer-note {
    font-family: GamjaFlower;
    color: #f4a800;
    font-size: 1.125rem;
    line-height: 1.375rem;
    transform: rotate(-15deg);
    text-align: left;
    position: absolute;
    top: -20px;
    left: -20px;
  }
}

.custom-tooltip {
  width: 55px;
  height: 25px;
  background-color: $c-purple;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -29px;
  left: 15%;
}

.custom-tooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent $c-purple transparent transparent;
}

.poll-carousel {
  box-shadow: 0px 2px 12px rgba(0, 45, 114, 0.4);
  background-color: #fff;
  border-radius: 1rem;

  .poll-widget {
    form,
    & > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.widget-carousel {
  .poll-carousel {
    height: 100%;

    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-dots {
      bottom: 1rem;
    }
  }
}
