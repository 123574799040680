.section-content {
  padding: 2rem 2rem 3rem;
  @media screen and (min-width: $break-sm) {
    padding: 2rem 2rem 4rem;
  }

  &-light-purple {
    background-color: $c-light-purple;
  }
  &-light-teal {
    background-color: $c-light-teal;
  }
  &-light-gray {
    background-color: #f8f8f8;
  }
  &-light-green {
    background-color: $c-light-green;
  }
  &-light-blue {
    background-color: $c-light-blue;
  }

  .widget,
  .trivia-carousel,
  .poll-carousel {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }
}