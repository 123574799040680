.section-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.75rem 2rem 1.75rem 2rem;
  @media screen and (min-width: $break-sm) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  @media screen and (min-width: $break-md) {
    &-wide {
      padding: 1.75rem 4.375rem;
    }
  }

  h2 {
    font-family: Bangers;
    color: $c-white;
    font-size: 2.375rem;
    line-height: 1;
    margin: 0 0 1rem;
    font-weight: normal;
    @media screen and (min-width: $break-sm) {
      margin: 0;
    }
    @media screen and (min-width: $break-md) {
      font-size: 3rem;
      line-height: 4.125rem;
    }

    .parent & {
      font-family: BebasNeue;
      font-weight: 800;
      letter-spacing: 0.05rem;
    }
  }

  &-purple {
    background-color: $c-purple;
  }

  &-teal {
    background-color: $c-teal;
  }
  &-dark-blue {
    background-color: $c-dark-blue;
  }

  &-green {
    background-color: $c-green;
  }

  &-blue {
    background-color: $c-blue;
  }

  &-navy {
    background-color: $c-navy;
  }
}

.section-filter {
  display: flex;
  align-items: center;

  &-item {
    font-family: BebasNeue;
    font-size: 1.5rem;
    line-height: 1;
    color: $c-white;
    margin: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    @media screen and (min-width: $break-sm) {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }

    &:hover {
      color: $c-white;
    }

    i {
      font-size: 1.75rem;
      background: $c-dark-blue;
      border: 6px solid $c-white;
      border-radius: 50%;
      width: 4.125rem;
      height: 4.125rem;
      display: inline-block;
      text-align: center;
      padding: 0.75rem 0;
      color: white;
    }

    span {
      display: inline-block;
      padding: 0.5rem;
      color: white;
    }

    &.selected {
      span {
        border-bottom-right-radius: 2rem;
        border-top-right-radius: 2rem;
        padding: 0.5rem 1rem;

        @media screen and (min-width: $break-sm) {
          background: $c-white;
          margin-left: -6px;
          color: $c-yellow;
        }
      }

      i {
        background: $c-yellow;
      }
    }
  }
}
