.search-head {
  @media screen and (max-width: 767px) {
    padding: 1.5rem 1rem 1.5rem 1rem;
  }
  form {
    button {
      background-color: #f4a800;
      color: #fff;
      .parent & {
        background-color: #0072ce;
      }
    }
  }
}

// Parent Styles
.parent {
  .results-content {
    .blog-post.search-result-post {
      .parent-guide-btn {
        display: inline-block;
      }
    }
  }
}

// Content
.results-content {
  background-color: #e7f7f5;
  .parent & {
    background-color: #eef7fe;
  }

  @media screen and (max-width: 767px) {
    .c-btn.medium {
      font-size: 1.125rem;
      padding: 0.375rem 1.25rem;
    }
  }

  .search-result-book {
    border-top: 10px solid #57b1f9;
    background: #fff;
    .book-wrapper {
      display: flex;
      flex-wrap: wrap;
      .cl-card-img {
        flex: 0 0 40%;
        @media screen and (min-width: 576px) {
          flex: 0 0 30%;
        }
        @media screen and (min-width: 992px) {
          flex: 0 0 20%;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .cont {
        flex: 0 0 60%;
        @media screen and (min-width: 576px) {
          flex: 0 0 70%;
        }
        @media screen and (min-width: 992px) {
          flex: 0 0 80%;
        }
      }
      .cont {
        color: #002d72;
        .book-title {
          padding: 1rem 0.7rem;
          border-bottom: 1px solid rgba(151, 151, 151, 0.1);
          @media screen and (min-width: 768px) {
            padding: 1.625rem 3.5rem 1.375rem 1.125rem;
          }

          .cont-title {
            font-size: 1.1875rem;
            color: #0072ce;
            font-weight: 600;
            @media screen and (min-width: 768px) {
              font-size: 1.75rem;
            }
          }
          .cont-subtitle {
            font-size: 0.9rem;
            line-height: 1.375;
            font-weight: 600;
            @media screen and (min-width: 768px) {
              font-size: 1rem;
            }
          }

          .c-card-raiting-reviews {
            margin-top: 0.5rem;
            .rating-stars {
              font-size: 1rem;
              line-height: 1;
              @media screen and (min-width: 768px) {
                font-size: 1.375rem;
              }
            }
            .rev-text {
              display: none;
              @media screen and (min-width: 768px) {
                display: block;
              }
            }
          }
        }

        .book-content {
          padding: 1rem 0.7rem;
          @media screen and (min-width: 768px) {
            padding: 1.5rem 1.375rem 1.5rem 1.125rem;
          }
        }
      }

      .parent-guide-btn {
        display: none;
      }
    }
    .cl-card-footer {
      background: #002d72;
      color: #fff;
      font-size: 0.8125rem;
      line-height: 1.125rem;
      padding: 0.5rem 0.625rem;
      .tick-icon {
        font-size: 1rem;
        line-height: 1;
        display: inline-block;
      }
      p.tick-text {
        margin-left: 0.5rem;
      }
    }
  }

  .blog-post {
    .blog-post-title {
      border-bottom: 1px solid rgba(151, 151, 151, 0.1);
      font-weight: 600;
      padding: 1rem;
      @media screen and (min-width: 768px) {
        padding: 1.625rem 1.875rem 1.125rem;
      }
    }
    .blog-post-content {
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 1rem;
      @media screen and (min-width: 768px) {
        padding: 1.5rem 1.875rem;
      }
    }

    .blog-post-image {
      max-height: 210px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &-lg {
        @media screen and (min-width: 992px) {
          .blog-post-image-bg {
            min-height: 230px;
          }
        }
      }
    }

    &.search-result-post {
      border-top: 10px solid #57b1f9;
    }
    .blog-post-bottom i.icon {
      color: #57b1f9;
    }
  }
}

.section-filter-item {
  background-color: inherit;
}

.news-feed-date-container {
  position: relative;
  top: 3.4375rem;
  left: -2.25rem;
  color: #002d72;
  font-family: GamjaFlower;
  i {
    vertical-align: -webkit-baseline-middle;
    color: #00c7b1;
    font-size: 2rem;
    margin-right: 0.5rem;
  }
}

.news-day-title {
  position: relative;
  left: -3.5rem;
  bottom: -2rem;
  color: #002d72;
  font-weight: bolder;
}

.news-feed-icon {
  float: left;
  width: 2.25rem;
}

.news-feed-date {
  height: 2.8125rem;
  padding-top: 0.6rem;
}
