.top-banner {
  padding-top: 15.75rem;
  padding-bottom: 10rem;
  background-image: url("../img/home-kids-top-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  border-bottom: 6px solid #fff;

  .top-banner-cont {
    .banner-text-title {
      color: white;
      font-size: 5.375rem;
      font-family: Bangers;
      line-height: 6.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      @media screen and (min-width: $break-sm) {
        font-size: 6.25rem;
      }
      @media screen and (min-width: $break-md) {
        font-size: 9rem;
      }
    }

    .banner-text-subtitle {
      padding: 0.75rem 0 0;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      font-family: Nunito;
      @media screen and (min-width: $break-sm) {
        font-size: 1.875rem;
      }
      @media screen and (min-width: $break-md) {
        font-size: 2.5rem;
      }
    }

    .banner-btn-holder {
      padding: 2rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .c-btn {
        margin: 0.5rem;
        font-size: 1.875rem;
        @media screen and (min-width: $break-md) {
          font-size: 2.5rem;
        }
      }
    }
  }
}

.pick-book-top {
  position: relative;
  background-color: $c-bright-blue;

  .pick-book-banner {
    display: flex;
    padding: 3rem 0 8rem;
    white-space: nowrap;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;

    .pick-book-banner-text {
      white-space: initial;
      padding: 1.5rem 0;
    }

    @media (min-width: $break-lg) {
      flex-wrap: nowrap;
      text-align: left;
      justify-content: flex-start;
      padding: 4.5rem 4.375rem 8rem;
      .pick-book-banner-text {
        padding: 0 4.5rem;
      }
    }
  }
}

.central-pj-section {
  padding-bottom: 5rem;
  &-inner {
    margin-top: -6rem;
    @media (min-width: $break-lg) {
      padding: 0 4.375rem;
    }
  }
  .widget-carousel  {
    .blog-post-content {
      a {
        display: table;
        margin-top: 0.8rem;
      }
    }
  }
}

.exploring {
  background-image: url("../img/burst-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 4rem 0 7rem 0;
  background-color: $c-purple;
  .parent & {
    background-color: $c-dark-blue;
  }

  .exploring-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .exploring-options {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 4rem;
      width: 100%;

      .option-card {
        margin: 1.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .option-card-icon {
          height: 8.5rem;
          margin-bottom: 1.5rem;
          display: flex;
          align-items: center;
        }
        .option-card-text {
          margin-bottom: 2.5rem;
        }

        button {
          min-width: 9.75rem;
        }
      }
    }
  }
}

.pick-book-cards {
  &-cont {
    margin-top: -6rem;
    @media screen and (min-width: $break-lg) {
      padding: 0 4.375rem;
    }
  }

  &-cont-tablet {
    margin-top: -6rem;
    .books-carousel .slick-slide {
      margin: 0 15px;
    }
  }
}

/*GLOBAL CLASSES*/
.white-text-24 {
  font-family: Nunito;
  font-size: 1.5rem;
  color: #ffffff;
}

.white-text-20 {
  font-family: Nunito;
  font-size: 1.25rem;
  color: $c-white;
  white-space: normal;
}

.h2-title {
  font-family: Bangers;
  font-size: 4.125rem;
  color: $c-white;
  text-transform: uppercase;
  .parent & {
    font-family: BebasNeue;
  }

  @media screen and (min-width: $break-sm) {
    font-size: 4.5rem;
  }

  @media screen and (min-width: $break-md) {
    font-size: 5.375rem;
  }
}

.stiker-block {
  height: 12.5rem;
  position: absolute;
  width: 15.8125rem;
  background-image: url("../img/sticker.svg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -9rem;
  transform: rotate(15deg);
  @media (min-width: $break-sm) {
    right: auto;
    transform: rotate(0deg);
  }

  .stiker-text {
    text-align: center;
    color: white;
    font-size: 2.375rem;
    font-family: Bangers;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    max-width: 11rem;
    transform: rotate(-15deg);

    @media (min-width: $break-md) {
      transform: rotate(-25deg);
    }

    &:after {
      content: "";
      border-right: 4px solid white;
      border-bottom: 4px solid white;
      border-left: transparent;
      border-top: transparent;
      height: 15px;
      width: 15px;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
      bottom: -15px;
      transform: rotate(45deg);
    }
  }
}

.home-latest-blog {
  margin-top: -6rem;
}

.widget-carousel {
  .poll-widget-answer {
    height: 100%;
  }

  .slick-track {
    display: flex;
    .item.slick-slide {
      height: inherit !important;
    }
  }

  .poll-widget > div {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;
  }

  &-tablet {
    .item.slick-slide {
      margin: 0 12px;
    }
  }

  .widget {
    position: relative;
    & > .public-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 1rem;
      color: #fff;
      font-size: 1.75rem;
      padding: 9rem 3rem;
      text-align: center;
      display: block;
      opacity: 0;
      transition: all 0.3s ease;
      a {
        text-decoration: underline;
        &:focus {
          outline: 0;
        }
      }
    }

    &:hover > .public-overlay {
      opacity: 1;
    }
  }
}
