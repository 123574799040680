.blog-featured {
  background-color: $c-teal;

  &-inner {
    padding: 5rem 0;

    @media screen and (min-width: $break-sm) {
      padding: 7rem 2rem;
    }
    @media screen and (min-width: $break-md) {
      padding: 6rem 3rem;
    }
  }

  .parent & {
    background-color: $c-blue;
  }

  .stiker-block {
    width: 145px;
    height: 115px;
    margin: 0;
    top: 8px;
    z-index: 2;
    .stiker-text {
      font-size: 1.75rem;
      transform: rotate(-25deg);
    }
    @media screen and (min-width: $break-sm) {
      width: 202px;
      height: 155px;
      top: -6px;
      left: -10px;

      .stiker-text {
        font-size: 2.375rem;
      }
    }

    @media screen and (min-width: $break-md) {
      top: 0;
      left: 0;
    }
  }
}

.blog-list {
  padding: 3rem 0;

  @media screen and (min-width: $break-sm) {
    padding: 4rem 2rem;
  }
  @media screen and (min-width: $break-md) {
    padding: 4rem 5rem;
  }
  @media screen and (min-width: $break-lg) {
    padding: 5rem 1rem 5rem 3rem;
    &-kids {
      padding: 5rem 9.25rem 3rem;
    }
  }

  .blog-post {
    margin-bottom: 2rem;
  }

  .blog-post-content {
    font-size: 1rem;
    line-height: 1.625rem;
  }

  .blog-post-image-bg {
    @media screen and (min-width: $break-md) {
      min-height: 230px;
    }
  }
}

.blog-light-bg {
  background-color: $c-light-teal;
  .parent & {
    background-color: $c-light-blue;
  }
}

/*Blog post list sidebar filter*/
.blog-filter {
  text-align: center;
  padding: 3.5rem 1rem;
  font-family: Nunito;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 300;
  height: 100%;
  h3,
  h4,
  a {
    color: $c-white;
  }
  .blog-filter-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5rem;
    flex-wrap: wrap;
    flex-direction: column;
    @media screen and (min-width: $break-sm) {
      flex-direction: row;
    }
    @media screen and (min-width: $break-md) {
      flex-direction: column;
    }

    h3 {
      font-size: 2.375rem;
    }
  }
  .header-icon {
    font-size: 8.5rem;
    line-height: 1;
    color: $c-white;
  }

  &-item {
    text-align: left;
    margin: 2rem 0;
    h4 > .icon {
      vertical-align: text-bottom;
      font-size: 1.375rem;
      line-height: 1;
      margin-right: 0.75rem;
      color: $c-yellow;
      .parent & {
        color: $c-bright-blue;
      }
    }

    &-tags {
      a {
        text-transform: capitalize;
        &:after {
          content: ",";
        }
        &:last-of-type:after {
          content: "";
        }
      }
    }
    &-list {
      color: #f9fafa;
      margin-left: 2.125rem;
      a:hover {
        text-decoration: underline;
      }
      .more-items {
        font-size: 1rem;
        line-height: 1.375rem;
        color: $c-white;
        i.icon {
          vertical-align: middle;
          font-size: 0.75rem;
        }
      }

      &.related-posts > a {
        display: block;
        margin-bottom: 1rem;
      }
    }
  }

  &-bg {
    background: $c-purple;
    .parent & {
      background: $c-dark-blue;
    }
  }
}

/*Blog post details*/
.blog-post-details {
  overflow: hidden;
  .parent & .section-header-teal {
    background: $c-dark-blue;
  }
}
.blog-post-main {
  @media screen and (min-width: $break-sm) {
    padding: 0 1rem;
  }

  h4 {
    margin: 5rem 0 3.5rem;
  }
}

/*Blog post commets*/
.blog-post-comments {
  padding: 3rem 0;
  @media screen and (min-width: $break-sm) {
    padding: 3rem 1rem;
  }
  @media screen and (min-width: $break-md) {
    padding: 4rem 0;
  }
  h2 {
    font-size: 2.375rem;
    line-height: 1;
    text-transform: uppercase;
    margin: 1rem 0;
    font-family: Nunito;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  &-item {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}
