.story-central .stiker-block {
  top: -3.5rem;
  @media screen and (min-width: $break-sm) {
    top: -7rem;
  }
}

.story-central {
  .pick-book-banner {
    padding: 10rem 0 6rem;
    @media screen and (min-width: $break-sm) {
      padding: 6rem 1rem 5rem;
    }
    @media screen and (min-width: $break-lg) {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 6rem 4.375rem 6rem;
    }
    h2 {
      white-space: normal;
    }
    .pick-book-banner-text {
      order: 2;
      @media screen and (min-width: $break-lg) {
        order: unset;
        padding: 0;
      }
    }
  }
}
.pick-book-top.story-central {
  background-color: #fff;
}

.parent {
  .story-central-filter {
    .section-header-teal {
      background-color: #0072ce;
    }
  }
  aside {
    .section-header-purple {
      background-color: #001f4f;
    }
  }
}

.story-central-filter {
  .section-filter {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.story-central-books-list {
  .parent-guide-btn {
    visibility: hidden;
    .parent & {
      visibility: visible;
    }
  }

  aside > form {
    height: 100%;
    #filter-form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.cl-card {
  border-top: 5px solid $c-bright-blue;
  border-bottom: 34px solid $c-dark-blue;
  background-color: #fff;
  display: flex;
  box-shadow: 0px 2px 15px rgba(0, 45, 114, 0.4);
  position: relative;

  .cl-card-img {
    max-width: 10rem;
    position: relative;
    img {
      width: 100%;
    }
  }
  .cont {
    position: relative;
    padding: 1.5rem;
    h5 {
      font-size: 1.75rem;
      font-weight: 600;
      line-height: 1;
    }
    .cont-subtitle {
      font-size: 1rem;
      font-weight: 600;
      color: $c-dark-blue;
    }
  }
  .c-card-option {
    position: absolute;
    bottom: -15px;
  }
  @media (max-width: 1201px) {
    margin-left: 0;
  }
}

.story-central {
  .cg-card {
    margin: 20px auto;
  }
}

// Aside
.story-central-books-list {
  aside {
    .section-content {
      padding: 1rem 1rem;
      @media screen and (min-width: 768px) {
        padding: 2rem 2rem 3rem;
      }
    }
    h4 {
      font-family: Nunito;
      font-weight: 400;
      font-size: 1.75rem;
      color: white;
      &[aria-expanded="false"] {
        i.icon-minus {
          display: none;
        }
      }
      &[aria-expanded="true"] {
        i.icon-plus {
          display: none;
        }
      }
    }
    // Custom Multiple Select
    #themes_chosen {
      ul {
        &.chosen-choices {
          border: 1px solid #c7c7c7;
          border-radius: 0.3125rem;
          font-size: 1rem;
          padding: 1rem 0.5rem 1rem 0.5rem;
          width: 100%;
          height: auto;
          line-height: 1.375rem;
          color: #929292;
          li {
            &.search-choice {
              background-color: #57b1f9;
              box-shadow: unset;
              border: 0;
              background-image: unset;
              color: #ffff;
              font-family: Nunito;
              font-weight: 600;
              padding-top: 0.3125rem;
              padding-bottom: 0.3125rem;
              .search-choice-close {
                top: 6px;
                background: url(../img/times-solid.svg);
                // background-image: unset !important;
                background-repeat: no-repeat !important;
                background-size: unset !important;
              }
            }
          }
        }
      }
      .chosen-drop {
        border-bottom-left-radius: 0.3125rem;
        border-bottom-right-radius: 0.3125rem;
        border-top: 1px solid #c7c7c7;
        margin-top: -5px;
        ul.chosen-results {
          font-family: Nunito;
          font-size: 1rem;
          padding: 0.5rem 0;
          width: 100%;
          height: auto;
          line-height: 1.375rem;
          color: #000;
          li.highlighted {
            background-color: transparent;
            background-image: unset;
            color: #000;
            &:hover {
              color: #000;
            }
          }
          li.result-selected {
            background-color: #eef7fe;
            color: #000;
          }
        }
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 144dpi),
  only screen and (min-resolution: 1.5dppx) {
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close {
    background-image: url(../../js-modules/assets/times-solid.svg) !important;
  }
}

aside {
  .form-group {
    label {
      font-family: Nunito;
      font-weight: 400;
      font-size: 1rem;
    }
  }
  .mobile-sort-filter {
    button {
      background-color: transparent;
      color: rgba(#fff, 0.7);
      text-transform: lowercase;
      font-family: Nunito;
      font-size: 1rem;
      i {
        font-size: 2rem;
        line-height: normal;
        padding-right: 0.4rem;
      }
      span {
        position: relative;
        display: block;
        margin-top: 2px;
        &:after {
          content: "";
          position: absolute;
          bottom: 4px;
          width: 100%;
          left: 0;
          height: 1px;
          background-color: rgba(#fff, 0.7);
        }
      }
    }
  }
}

.chosen-container.chosen-container-multi {
  width: 100% !important;
}

@media (max-width: 991px) {
  .card-list {
    max-width: unset;
    width: 100%;
    .cl-card {
      max-width: unset;
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .story-central-filter {
    .tablet {
      .section-header {
        width: 100%;
        justify-content: center;
        h2 {
          margin-bottom: 0;
        }
      }
      .section-filter {
        display: none;
      }
      .browse-book-filter {
        .input-pj-group {
          margin: 0 0 1rem 0 !important;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .cl-card {
    /*height: 251px;
    max-width: 316px !important;
    margin: 20px auto;*/
    .cl-card-img {
      height: 100%;
      width: 35%;
      img {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
    .cont {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0.5rem;
      justify-content: space-between;
      width: 65%;
      .c-card-raiting-reviews {
        order: 1;
        .c-card-reviews {
          .rev-text {
            display: none;
          }
        }
        @media (max-width: 340px) {
          .c-card-raiting {
            font-size: 0.8125rem;
          }
        }
      }
      .cont-title {
        margin-top: auto;
        order: 2;
        font-size: 1.25rem;
      }
      .cont-subtitle {
        order: 3;
        margin-bottom: auto !important;
        font-size: 0.875rem;
      }
      .c-card-tick {
        margin-bottom: 1rem;
        order: 4;
        font-size: 0.6875rem;
      }
    }
  }
}

.burst-bg-small {
  background-image: url(../img/burst-bg-small.svg);
  background-color: $c-purple;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .parent & {
    background-color: $c-dark-blue;
  }
}

.book-details {
  .signup-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #fff;
    // padding: 8.75rem 3rem 7rem;
    padding: 0 3rem 7rem;
    min-height: 39.375rem;
    .text-header {
      font-family: Bangers;
      font-size: 4.5rem;
      line-height: 4.75rem;
      margin-bottom: 2.25rem;
    }
    .text-section {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
    .hint-text {
      font-family: GamjaFlower;
      font-size: 1.75rem;
      line-height: 2.25rem;
      margin-top: 1.25rem;
      .parent & {
        display: none;
      }
    }
    .section-btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1.5rem 0;
    }
    .text-login {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.375rem;
      a {
        color: #fff;
        display: block;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .btn-sign-up {
      display: none;
      .parent & {
        display: inline-block;
      }
    }
  }
}

.aside-column {
  & > div:last-child {
    height: 100%;
  }
  .section-content-light-purple.filler-column {
    .parent & {
      background-color: $c-light-blue;
    }
  }
}

.rating-stars {
  ul {
    list-style-type: none;
    padding: 0;
    -moz-user-select: none;
    -webkit-user-select: none;
  }
}
.rating-stars ul > li.star {
  display: inline-block;
}

.parent-guide-summary {
  font-size: 20px;
  color: $c-navy;
  line-height: 30px;
}

.parent-guide-btn {
  display: none;
  .parent & {
    display: inline-block;
  }
}

.parent .book-details {
  .section-header-teal {
    background-color: $c-navy;
  }
  .section-content-light-teal {
    background-color: $c-light-blue;
  }
}

.filter-not-found {
  padding: 1.75rem 4.375rem;
  h4 {
    font-size: 2rem;
    margin-top: 3rem;
  }
}

.book-selection-buttons {
  display: inline-block;
  text-align: center;
  .book-details .page-header-big & .c-btn.small {
    font-size: 1.5rem;
    padding: 0.5rem 1.5rem;
  }
}
