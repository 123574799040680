.slick-slider {
  .slick-next,
  .slick-prev {
    color: white;
    border-radius: 50%;
    font-size: 24px;
    height: 48px;
    width: 48px;
    background-color: $c-dark-blue;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: 10rem;
    i {
      line-height: 24px;
      display: flex
    }
  }
  .slick-next.slick-disabled,
  .slick-prev.slick-disabled {
    opacity: 0.4;
  }
  .slick-next:hover,
  .slick-prev:hover {
    background-color: $c-blue;
  }

  .slick-prev:before,
  .slick-next:before {
    content: none;
  }
  .slick-next {
    right: 0;
    @media (min-width: $break-lg) {
      right: -5rem;
    }
  }
  .slick-prev {
    left: 0;
    @media (min-width: $break-lg) {
      left: -5rem;
    }
  }

  .slick-next,
  .slick-prev {
    top: auto;
    bottom: -6rem;

    @media (min-width: $break-lg) {
      top: 10rem;
      bottom: auto;
    }
  }

  .slick-dots {
    bottom: -4rem;
    li {
      button {
        width: 0.875rem;
        height: 0.875rem;
        border-radius: 50%;
        background: rgba(0, 45, 114, 0.3);
        &:before {
          content: none;
        }
        &:hover {
          background: $c-dark-blue;
        }
      }

      &.slick-active {
        button {
          background: $c-dark-blue;
        }
      }
    }
  }
}
.widget-carousel .slick-list {
  padding-bottom: 1rem;
  box-sizing: content-box;
}

.trivia-green {
  .slick-dots li {
    button {
      background: rgba(92, 146, 25, 0.4);
      &:hover {
        background: $c-dark-green;
      }
    }
    &.slick-active button {
      background: $c-dark-green;
    }
  }
}

.poll-carousel {
  .slick-dots li {
    button {
      background: rgba(77, 22, 89, 0.3);
      &:hover {
        background: $c-dark-purple;
      }
    }
    &.slick-active button {
      background: $c-dark-purple;
    }
  }
}
