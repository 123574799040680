.trivia-card {
  border-radius: 1rem;
  padding: 3rem;
  text-align: center;

  &-title {
    font-family: Bangers;
    font-size: 4rem;
    line-height: 1;
    color: $c-purple;

    .trivia-green & {
      color: $c-green;
    }
  }

  &-icon {
    font-size: 3.75rem;
    color: $c-white;
    line-height: 1;
  }

  &-question {
    &-text {
      font-family: Nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 1.75rem;
      line-height: 2.375rem;
      color: $c-navy;
      margin: 2.5rem 0;
    }
  }

  &-count {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2.375rem;;
    color: $c-navy;
    margin-bottom: 0.5rem;
  }

  &-answer {
    //display: none;
    .trivia-card-title, .trivia-card-count {
      color: $c-white;
    }
    &-text {
      font-family: Nunito;
      font-weight: 600;
      font-size: 1.75rem;
      line-height: 2.375rem;
      color: $c-white;
      margin: 2.5rem 0;
    }
    .c-btn-white,
    .c-btn-white:hover {
      color: $c-purple;
      .trivia-green & {
        color: $c-green;
      }
    }
  }
  &.answered {
    background-color: $c-purple;
    .trivia-green & {
      background-color: $c-green;
    }
  }
}

.trivia-carousel {
  box-shadow: 0px 2px 24px rgba(192, 192, 192, 0.5);
  border-radius: 1rem;
  background: $c-white;
  .slick-track {
    display: flex;
    .trivia-card {
      display: flex;
      height: auto;
      align-items: center;
      justify-content: center;
    }
  }
  .slick-dots {
    bottom: -3rem;
  }
}

.trivia-answerd-hint {
  font-family: GamjaFlower;
  color: $c-green;
  font-size: 1.25rem;
  margin-top: 2rem;
  line-height: 1;
}

/*Trivia widget inside home page carousel*/
.widget-carousel {
  .trivia-carousel {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    box-shadow: 0px 2px 12px rgba(0, 45, 114, 0.4);
    border-radius: 1rem;

    .trivia-card {
      box-shadow: none;
      border-radius: 0;
    }

    .slick-list,
    .slick-track {
      height: 100%;
    }
    .slick-list {
      padding-bottom: 0;
      border-radius: 1rem;
    }

    .slick-dots {
      bottom: 1rem;
    }
    &.slick-dotted.slick-slider {
      margin-bottom: 0;
    }
  }
}
