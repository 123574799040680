.mainBookCard {
  margin-top: 2.5rem;
  border-top: 5px solid #57b1f9;
  border-bottom: 34px solid #002d72;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 2px 25px rgba(0, 45, 114, 0.4);
  position: relative;
  max-height: 16rem;
  @media screen and (min-width: 576px) {
    min-height: unset;
  }
  @media screen and (min-width: 768px) {
    max-width: 615px;
    margin-top: 6rem;
  }
  @media screen and (min-width: 992px) {
    max-width: 555px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 730px;
  }
  .c-card-badges {
    z-index: 16;
    display: none;
    @media screen and (min-width: 768px) {
      display: flex;
    }
  }
  // Start List View
  &__thumbnail {
    z-index: 10;
    @media screen and (min-width: 768px) {
      width: 10.5rem;
      box-shadow: -1px -1px 10px 0 rgba(0, 45, 114, 0.4);
    }
    &__img {
      height: 100%;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    @media screen and (min-width: 768px) {
      transform: scale(1.3) translateY(0.75rem);
    }
  }
  &__info {
    font-family: Nunito;
    background: #ffffff;
    width: 100%;
    padding-left: 15px;
    position: relative;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
      padding-bottom: 0.7rem;
      padding-right: 0.7rem;
    }
    @media screen and (min-width: 768px) {
      padding-left: 45px;
      padding-bottom: 15px;
      padding-right: 25px;
    }
    &__title {
      padding-top: 25px;
      h2 {
        font-family: Nunito;
        font-weight: 600;
        font-size: 1.1875rem;
        color: #0072ce;
        line-height: normal;
        @media screen and (min-width: 576px) {
          font-size: 1.3125rem;
        }
        @media screen and (min-width: 768px) {
          font-size: 1.75rem;
          line-height: 38px;
        }
      }
      @media screen and (max-width: 767px) {
        order: 2;
        padding-top: 0;
      }
    }
    &__sub-title {
      h5 {
        font-family: Nunito;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: #002d72;
      }
      @media screen and (min-width: $break-md) {
        order: 3;
        h5 {
          font-size: 1rem;
        }
      }
    }
    &__rating {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      @media screen and (max-width: 767px) {
        order: 1;
        padding-top: 0.7rem;
        &__reviews {
          p {
            display: none;
          }
        }
      }
      &__stars {
        i {
          color: #f28028;
          font-size: 0.875rem;
          @media screen and (min-width: 576px) {
            font-size: 1rem;
          }
        }
      }
      &__reviews {
        display: flex;
        align-items: center;
        margin-left: 25px;
        span {
          font-family: BebasNeue;
          font-size: 1rem;
          line-height: 1;
          color: #fff;
          background-color: #f28028;
          padding: 0.375rem;
          border-radius: 1.25rem;
          margin-right: 5px;
        }
        p {
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #f28028;
        }
      }
    }
    &__tags {
      font-family: Nunito;
      font-size: 0.8125rem;
      line-height: 1.125rem;
      color: #002d72;
      margin-bottom: 0.8125rem;
      .icon {
        vertical-align: middle;
        margin-right: 2px;
      }
      a {
        color: #002d72;
        &:after {
          content: ",";
        }
        &:last-of-type:after {
          content: "";
        }
      }
      @media screen and (max-width: 767px) {
        order: 4;
        font-size: 11px;
      }
    }
    &__actions {
      position: absolute;
      display: flex;
      flex-direction: column;
      left: 0;
      bottom: -18px;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      height: 34px;
      @media screen and (min-width: 576px) {
        justify-content: flex-start;
        left: 15px;
      }
      /*@media screen and (max-width: 767px) {
        order: 5;
      }*/
      a {
        margin: 3px;
      }
      a:not(:first-child) {
        margin-top: 0.25rem;
      }
      @media screen and (min-width: 768px) {
        left: 45px;
        bottom: -15px;
        display: block;
        a:not(:first-child) {
          margin-top: 0;
        }
      }
    }
  }
}

// Start Grid
.card-items.grid {
  @media screen and (min-width: 768px) {
    .items-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 15px;
    }

    .mainBookCard {
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      flex: 0 0 calc(50% - 15px);
      max-width: 20rem;
      box-shadow: unset;
      border-top: unset;
      margin-left: 0;
      margin-right: 0;
      max-height: none;

      .c-card-badges {
        right: 0;
      }
      .mainBookCard__thumbnail {
        transform: none;
        margin: 0 2rem;
        margin-bottom: -3.5rem;
        z-index: 2;
        position: relative;
        width: calc(100% - 4rem);
      }
      .mainBookCard__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 5px solid #57b1f9;
        box-shadow: 0px 2px 25px rgba(0, 45, 114, 0.4);
        min-height: 20rem;
        padding: 3.5rem 2rem 2rem;
        text-align: center;
        flex: 1 0 auto;
        &__title {
          order: 2;
          flex: 1 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        &__tags {
          order: 3;
        }
        &__actions {
          order: 4;
          left: 0;
          right: 0;
        }
        &__rating {
          order: 1;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-bottom: 0;
          &__reviews {
            display: flex;
            align-items: center;
            margin-left: 0;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1200px) {
    padding: 0 50px;
    justify-content: space-between;
    .mainBookCard {
      flex: 0 0 calc(33.333333% - 30px);
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}
