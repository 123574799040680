.wysiwyg {
    color: #555555;
    p {
        margin-bottom: 1rem;
        line-height: 1.625rem;
    }

    ul {
        padding: 1rem 2rem;
        li {
            display: list-item;
            list-style: disc;
        }
    }
}
