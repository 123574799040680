h1,
.h1 {
  font-family: Bangers;
  font-size: 4rem;
  line-height: 4rem;
  font-weight: normal;
  font-style: normal;
  color: $c-blue;
}

h2,
.h2 {
  font-family: Bangers;
  font-size: 3.25rem;
  line-height: 3.5rem;
  font-weight: normal;
  font-style: normal;
  color: $c-blue;
}

h3,
.h3 {
  font-family: Nunito; //replacement font
  font-size: 1.75rem;
  line-height: 2.375rem;
  font-weight: normal;
  font-style: normal;
  color: $c-blue;
}

h4,
.h4 {
  font-family: Nunito; //replacement font
  font-size: 1.3125rem;
  line-height: 1.8125rem;
  font-weight: normal;
  font-style: normal;
  color: $c-blue;
}

h5,
.h5 {
  font-family: Nunito; //replacement font
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: normal;
  font-style: normal;
  color: $c-blue;
}

h6,
.h6 {
  font-family: Nunito; //replacement font
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: normal;
  font-style: normal;
  color: $c-blue;
}

.page-title {
  font-family: Bangers;
  font-size: 3.375rem;
  line-height: 3.5625rem;
  font-weight: normal;
  font-style: normal;
  color: $c-blue;
  position: relative;

  @media screen and (min-width: $break-sm) {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }

  &:after {
    content: "";
    border-bottom: 5px solid $c-yellow;
    display: block;
    width: 56px;
    position: absolute;
    left: calc(50% - 28px);
    border-radius: 5px;
    margin-top: 1rem;
  }
}

.parent {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  .page-title {
    font-family: BebasNeue;
  }
  .page-title:after {
    border-bottom: 5px solid $c-bright-blue;
  }
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-weight: 600;
  }
}
