.cg-card {
  position: relative;
  margin-bottom: 1.5rem;
  max-width: 20rem;
  height: calc(100% - 1.5rem);
  display: flex;
  flex-direction: column;

  &-details {
    position: relative;
    padding: 3.5rem 2rem 2rem;
    text-align: center;
    border-top: 5px solid $c-blue;
    background-color: $c-white;
    box-shadow: 0px 2px 15px rgba(0, 45, 114, 0.4);
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  &-banner {
    margin: 0 2rem;
    margin-bottom: -3.5rem;
    z-index: 2;
    position: relative;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .c-card-desc {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: center;
  }
  .c-card-option {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    left: 0;
    top: -15px;
    right: 0;
    margin: 0 auto;
    padding: 0 1rem;
    button, .c-btn {
      white-space: nowrap;
      margin: 3px;
    }
  }
  .cg-card-footer {
    position: relative;
    width: 100%;
    left: 0;
    height: 2.125rem;
    background-color: #002d72;
  }
}

.c-card-raiting {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-card-reviews {
  display: flex;
  justify-content: center;
  align-items: center;
  .rev-count {
    font-family: BebasNeue;
    font-size: 1rem;
    color: $c-white;
    background-color: $c-orange;
    padding: 0.375rem;
    border-radius: 12.5px;
    margin-right: 5px;
    line-height: 1;
  }
  .rev-text {
    color: $c-orange;
    font-family: Nunito;
    font-size: 0.8125rem;
  }
}

.c-card-desc {
  .c-card-title {
    font-family: Nunito;
    font-size: 1.75rem;
    font-weight: 600;
    color: #0072ce;
    margin-bottom: 0.75rem;
    line-height: 2.375rem;
  }
  .c-card-subtitle {
    font-family: Nunito;
    font-size: 0.875rem;
    font-weight: 600;
    color: #002d72;
    margin-bottom: 1rem;
  }
}

.c-card-tick {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $c-dark-blue;
  line-height: 1;
  i {
    margin-right: 0.5rem;
  }
  .tick-text {
    font-family: Nunito;
    font-size: 0.8125rem;
  }
  a {
    color: #002d72;
    &:after {
      content: ",";
    }
    &:last-of-type:after {
      content: "";
    }
  }
}

.c-card-badges {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: absolute;
  top: -8px;
  right: -34px;
  .badge {
    margin: 4px 0px;
    height: 68px;
    width: 68px;
    img {
      height: 100%;
      width: 100%;
    }
  }
}

.related-books-slider .cg-card {
  margin-left: auto;
  margin-right: auto;
}
