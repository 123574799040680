@import "../core/variables";


.inf-section {
  display: flex;

  .inf-section-banner {
    min-height: 300px;
    min-width: 300px;
    width: 35%;
    padding: 4.375rem 3.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    img {
      height: 130px;
      margin-bottom: 30px;

    }

    h4 {
      font-size: 3rem;
      line-height: 3rem;
      text-align: center;
      font-family: BebasNeue;
      font-weight: bold;
    }

  }

  .inf-section-content {
    width: 65%;
    padding: 5rem 3.75rem;
    display: flex;
    flex-direction: column;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.625rem;
      color: #555555;

      a {
        color: $c-dark-blue;
        text-decoration: none;
        font-weight: 600;
      }
    }



    @media (max-width: 992px) {
      padding: 3rem 1rem;
    }

  }

  @media (max-width: 992px) {
    .inf-section-banner {
      img {
        min-height: 100px;
      }
    }
  }

  @media (max-width: 769px) {
    flex-direction: column;
    .inf-section-banner {
      width: 100%;

      &.section-content{
        padding: 2.375rem 1rem !important;

      }
    }

    .inf-section-content {
      width: 100%;
    }
  }
}

.input-pj-group {

  .file-upload-inp {
    width: 100%;
    border-bottom: 1px solid #C7C7C7;
    padding: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    input[type='file'] {
      display: none;
      visibility: hidden;
    }

    label {
      color: $c-white;
    }
  }
}

.how-it-works, .incentive, .letter {
  .section-content {
    padding: 4.375rem 1rem;
  }
}

.letter {

  .input-pj-group {
    min-width: 200px;
  }

}
