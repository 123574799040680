.badges-info {
  @include profileInfoSection(#702082, #f0eaf1, #4d1659, #702082, #4d1659);
}

.badges-container {
  //.container-fluid {
  padding: 2rem 1.5625rem;
  @media screen and (min-width: 1200px) {
    padding: 4.25rem 5.5rem 6.25rem 5.5rem;
  }
  //}
  .badges-title,
  .badges-sub-title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
  }
  .badges-title {
    font-size: 1.75rem;
    line-height: 2.375rem;
    color: #4d1659;
  }
  .badges-sub-title {
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: #001f4f;
  }
  @media screen and (min-width: 1200px) {
    .badges-title,
    .badges-sub-title {
      margin-left: 1.85rem;
    }
  }
  &.bg-grey {
    background-color: #f7f7f7;
  }
  .badge-item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &.disabled .badge-item-text h4 {
      opacity: 0.3;
    }
    &.large-badge {
      margin-top: 3.125rem;
      @media screen and (min-width: 992px) {
        margin-top: 0;
      }
      &.head {
        margin-top: 3.125rem;
        @media screen and (min-width: 992px) {
          margin-top: -3rem;
        }
      }
      .badge-item-text {
        h4 {
          font-size: 1.5rem;
          font-weight: 600;
        }
        p {
          font-size: 1.25rem;
          margin-bottom: 0;
        }
      }

      .badge-item-img {
        width: 13.875rem;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
    }
    margin-top: 2.3125rem;
    height: calc(100% - 2.3125rem);
    &-img {
      width: 7.25rem;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    &-text {
      font-family: Nunito;
      padding-top: 1.1875rem;
      h4 {
        font-size: 1rem;
        font-weight: bold;
        line-height: normal;
        color: #4d1659;
      }
      p {
        font-size: 0.875rem;
        font-weight: normal;
        line-height: 1.1875rem;
        color: #555555;
        font-style: italic;
      }
    }
  }
  &.bonus-badges {
    padding-top: 1rem;
    .stiker-block {
      width: 20rem;
      height: 16rem;
      position: relative;
      margin: 0;
      top: auto;
      @media screen and (min-width: 768px) {
        margin-left: -2rem;
      }
      @media screen and (min-width: 1200px) {
        margin-left: -4rem;
      }

      .stiker-text {
        max-width: 15rem;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        .badges-group-title {
          width: 50%;
          margin-left: 1rem;
        }
      }
    .bonus:nth-child(3) {
      margin-top: 8rem;
    }
  }
  // Show Hide Badges
  .badges-show-hide {
    background-color: #4d1659;
    max-width: 8.75rem;
    border-radius: 0.90625rem;
    color: #ffffff;
    span {
      font-weight: 600;
      font-size: 1rem;
      line-height: 22px;
      padding: 0 0 0 8px;
    }
    button {
      font-family: BebasNeue;
      font-size: 1.125rem;
      background-color: #702082;
      border-radius: 0.90625rem;
      padding: 0 8px 0 8px;
    }
  }
}
